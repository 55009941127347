<template>
  <div class="download">
    <!-- @btn="to('index')" -->
    <!-- @btn="to('login')" -->
    <navlist :curIndex="3" @login="$emit('visi')" />
    <div class="dowTopBox">
      <img class="topImg" src="./../../public/img/dowTopbg.png" alt="">
      <img class="titleTop" src="./../../public/img/titleTop.png" alt="">
      <div class="dowBtnBox">
        <div class="btnDowBox" @click="downBtn(1)">
          <img class="iconBtn" src="./../../public/img/iosDow.png" alt="">
          <div class="dowBtn"></div>
        </div>
        <div class="btnDowBox" @click="downBtn(2)">
          <img class="iconBtn" src="./../../public/img/anzhuo.png" alt="">
          <div class="dowBtn"></div>
        </div>
        <div class="btnDowBox" @click="downBtn(3)">
          <img class="iconBtn" src="./../../public/img/mac.png" alt="">
          <div class="dowBtn"></div>
        </div>
        <div class="btnDowBox" @click="downBtn(4)">
          <img class="iconBtn" src="./../../public/img/win.png" alt="">
          <div class="dowBtn"></div>
        </div>
      </div>
    </div>
    <bluehead title="下载ARK客户端" subtitle="
      使用ARK移动端应用程序，与您的团
      队成员随时随地沟通
      " src="/img/xiazaitop.png" btn="iOS" @btn="downBtn(1)" btn1="Android" @btn1="downBtn(2)" down="随时，随地，随心，互通，互联"
      subdown="元宇宙营销展会，元宇宙沉浸式办公，元宇宙企业招聘，元宇宙校院宣讲会" class="PhoneBox" />
    <div class="PhoneBox" style="padding-bottom: 30px;">
      <img class="btnPhone" @click="downBtn(5)" src="./../../public/img/macBtnPhone.png" alt="">
      <img class="btnPhone" @click="downBtn(5)" src="./../../public/img/winBtnPhone.png" alt="">
    </div>
    <bluepictext src="/img/mockUp.png" type="lineend" head="随身的元宇宙办公室" content="
      客户的临时会议，却正在通勤中？
一闪而过的灵感，想跟同事们分享？
ARK提供安卓端和iOS端，把元宇宙办公室装进口袋，随时随地connected-work！" hide="true" btn="iOS下载" @btn="downBtn(1)" />
    <bluepictext src="/img/mockUp2.png" verse="true" type="highhead" title="简约，但不简单" content="ARK建模精致、功能齐全，只是——
手机性能不够，会不会跑不起来？
ARK精心挑选了常用top 10的功能，打造极简移动端，轻负荷，更流畅！" hide="true" btn="Android下载" @btn="downBtn(2)" />
    <div class="out">
      <div class="w1040 infolist">
        <contactinfo />
      </div>
    </div>
    <bottom />
    <div class="toast" v-if="toast === 1">请在电脑端访问</div>
    <div class="wxMantle" v-if="wxMantle"><img src="./../../public/img/wxJumpImg.png" alt=""></div>
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bluehead from '../components/bluehead.vue'
import bluepictext from '../components/bluepictext.vue'
import bottom from '../components/bottom.vue'
import contactinfo from '../components/contactinfo.vue'
import { mapState } from 'vuex'

export default {
  name: 'download',
  components: {
    navlist: nav,
    bluehead,
    bluepictext,
    contactinfo,
    bottom
  },
  data() {
    return {
      list: [],
      downloadUrl: null,
      toast: 0,
      wxMantle: false
    }
  },
  mounted() {
    var dataNum
    if (location.search === '') {
      dataNum = '?'
    } else {
      dataNum = location.search
    }
    this.downloadUrl = 'https://www.huadongmeta.com/onlineark/index.html#/entrance/download' + dataNum
    window.scrollTo(0, 0)
    window.addEventListener('scroll', (e) => {
      if (window.innerWidth < 600) {
        if (window.pageYOffset + window.innerHeight > 830 && this.list.indexOf('p1') === -1) {
          this.list.push('p1')
        }
        if (window.pageYOffset + window.innerHeight > 1280 && this.list.indexOf('p2') === -1) {
          this.list.push('p2')
        }
        if (window.pageYOffset + window.innerHeight > 1700 && this.list.indexOf('p3') === -1) {
          this.list.push('p3')
        }
        if (window.pageYOffset + window.innerHeight > 2070 && this.list.indexOf('p4') === -1) {
          this.list.push('p4')
        }
        if (window.pageYOffset + window.innerHeight > 2500 && this.list.indexOf('p5') === -1) {
          this.list.push('p5')
        }
        if (window.pageYOffset + window.innerHeight > 2900 && this.list.indexOf('p6') === -1) {
          this.list.push('p6')
        }
        if (window.pageYOffset + window.innerHeight > 3300 && this.list.indexOf('p7') === -1) {
          this.list.push('p7')
        }
        if (window.pageYOffset + window.innerHeight > 3800 && this.list.indexOf('p8') === -1) {
          this.list.push('p8')
        }
      }
      if (window.pageYOffset + window.innerHeight > 1470 && this.list.indexOf('p1') === -1) {
        this.list.push('p1')
      }
      if (window.pageYOffset + window.innerHeight > 1960 && this.list.indexOf('p2') === -1) {
        this.list.push('p2')
      }
      if (window.pageYOffset + window.innerHeight > 2400 && this.list.indexOf('p3') === -1) {
        this.list.push('p3')
      }
      if (window.pageYOffset + window.innerHeight > 2880 && this.list.indexOf('p4') === -1) {
        this.list.push('p4')
      }
      if (window.pageYOffset + window.innerHeight > 3360 && this.list.indexOf('p5') === -1) {
        this.list.push('p5')
      }
      if (window.pageYOffset + window.innerHeight > 3830 && this.list.indexOf('p6') === -1) {
        this.list.push('p6')
      }
      if (window.pageYOffset + window.innerHeight > 4290 && this.list.indexOf('p7') === -1) {
        this.list.push('p7')
      }
      if (window.pageYOffset + window.innerHeight > 4920 && this.list.indexOf('p8') === -1) {
        this.list.push('p8')
      }
    })
  },
  computed: {
    ...mapState(['isphone'])
  },
  methods: {
    to() {
      // window.scrollTo(0, 0)
      // this.$router.push({
      //   path: './' + str
      // })
      window.open(this.downloadUrl)
    },
    downBtn(tiem) {
      if (tiem === 1) {
        var ua = navigator.userAgent.toLowerCase()
        // eslint-disable-next-line
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          // eslint-disable-next-line
          this.wxMantle = true
        } else {
          if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            window.open('https://apps.apple.com/cn/app/ark%E5%85%83%E5%AE%87%E5%AE%99%E5%B9%B3%E5%8F%B0/id1637842997?l=en')
          } else {
            window.open('https://apps.apple.com/cn/app/ark%E5%85%83%E5%AE%87%E5%AE%99%E5%B9%B3%E5%8F%B0/id1637842997?l=en')
          }
        }
      } else if (tiem === 2) {
        window.open('https://www.huadongmeta.com/apk/ark.apk')
      } else if (tiem === 3) {
        window.open('https://www.huadongmeta.com/apk/ARK%E5%85%83%E5%AE%87%E5%AE%99%E5%B9%B3%E5%8F%B0.dmg')
      } else if (tiem === 4) {
        window.open('https://www.huadongmeta.com/apk/ARK%E5%85%83%E5%AE%87%E5%AE%99%E5%B9%B3%E5%8F%B0.exe')
      } else if (tiem === 5) {
        this.toast = 1
        setTimeout(() => { this.toast = 0 }, 3000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wxMantle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 123456;

  img {
    position: absolute;
    right: 10%;
    top: 2%;
    width: 70%;
  }
}

.download {
  background: #F6F6F6;

  >.bluepictext {
    margin-bottom: 100px;

    &:nth-child(3) {
      margin-top: 80px;
    }

    &:not(.verse) /deep/ .item {
      &:first-child {
        margin-right: 100px;
      }
    }
  }

  .out {
    background: #D9F9E5;
  }

  .w1040 {
    margin: 0 auto;
    width: 1040px;
    overflow: hidden;
    padding-top: 80px;
  }

  .infolist {
    .tt {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #26305F;
      line-height: 70px;
      -webkit-text-stroke: 1px #26305F;
      text-stroke: 1px #26305F;
      text-align: left;
    }

    .list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 80px;

      .infocard:not(:last-child) {
        margin-right: 32px;
      }

      .infocard {}
    }
  }

  .dowTopBox {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding-top: 300px;

    .topImg {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
    }

    .titleTop {
      width: auto;
      position: relative;
      margin-bottom: 100px;
    }

    .dowBtnBox {
      position: relative;
      width: 1064px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;

      .btnDowBox {
        cursor: pointer;

        .iconBtn {
          width: 218px;
          height: 218px;
          flex: none;
        }

        .dowBtn {
          width: 218px;
          height: 218px;
          background: url('./../../public/img/dow.png') no-repeat 50% 10%;
          flex: none;
          display: none;
          box-shadow: 0px 32px 20px -21px #8690CD;
        }

        &:hover {
          .dowBtn {
            display: block;
          }

          .iconBtn {
            display: none;
          }
        }

      }
    }
  }

  .PhoneBox {
    display: none;
  }
}

@media screen and (max-width: 860px) {
  .download {
    .bluepictext {
      margin: 40px 0 !important;

      /deep/ {
        .bluetextinfo {
          height: auto !important;
        }
      }
    }

    .w1040 {
      width: 100%;

      .tt {
        text-align: center;
      }

      .list {
        flex-direction: column;
        width: 100%;
      }
    }

    .dowTopBox {
      display: none;
    }

    .PhoneBox {
      background: #252B4F;
      display: flex;
      padding: 0;
      padding-bottom: 2px;
      background: #252B4F;
      flex-direction: column;
      align-items: center;

      .btnPhone {
        width: 300px;
        margin-bottom: 15px;
        margin-top: 15px;
      }
    }
  }
}

.toast {
  margin: 0 auto;
  padding: 10px;
  width: max-content;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 15%;
  color: #fff;
  border-radius: 8px;
  left: 0;
  right: 0;
}
</style>
