<template>
  <div class="board">
    <div class="shadow" v-show="whiteStyle"></div>
    <div :class="[whiteStyle ? 'nav-white' : 'nav']">
      <div class="first" @click="to('index')"> <img class="logo" src="@/assets/ark-logo.png" /></div>
      <div class="right">
        <div class="item" @click="clickItem(0)">
          <img class="img" src="@/assets/solution.png" />
          <div class="indicator" v-if="curIndex == 0"></div>
        </div>
        <div class="item" @click="clickItem(1)">案例<div class="indicator" v-if="curIndex == 1"></div>
        </div>
        <div class="item" @click="clickItem(2)"> 登录 <div class="indicator" v-if="curIndex == 2"></div>
        </div>
        <div class="item" @click="clickItem(3)">下载ARK <div class="indicator" v-if="curIndex == 3"></div>
        </div>
        <div class="item" @click="clickItem(4)">帮助中心 <div class="indicator" v-if="curIndex == 4"></div>
        </div>
        <div class="item" @click="clickItem(5)">代理合作 <div class="indicator" v-if="curIndex == 5"></div>
        </div>
        <div class="item" @click="clickItem(6)">咨询 <div class="indicator" v-if="curIndex == 6"></div>
        </div>
      </div>
      <img class="sli" src="@/assets/nav-right.png" v-if="!isdrop" @click="isdrop = !isdrop" />
      <img class="sli" v-else src="@/assets/nav-right.png" @click="isdrop = !isdrop" />
      <div class="dropp" :class="{ on: isdrop }">
        <div class="d" @click="topage('index','解决方案')">解决方案</div>
        <div class="d" @click="topage('ex','案例')">案例</div>
        <div class="d" @click="totpage">登录</div>
        <div class="d" @click="topage('download','下载ARK')">下载ARK</div>
        <div class="d" @click="openUrl('price','帮助中心')">帮助中心</div>
        <div class="d" @click="topage('cooperative','代理合作')">代理合作</div>
        <div class="d" @click="to('self','咨询')">咨询</div>
      </div>
    </div>
    <div class="popUp phone" v-if="phoneShow">
      <img class="close" src="./../../public/img/close.png" alt="" @click="closeShow">
      <div class="popTop">温馨提示</div>
      <div class="popTitle">欢迎使用ARK！</div>
      <div class="popText">为了给您带来更好的使用体验，请前往PC端登录哦～</div>
      <div class="popBtn" @click="closeShow">我知道了</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navlist',
  props: {
    msg: String,
    curIndex: Number
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  data() {
    return {
      whiteStyle: false,
      maxScroll: 85,
      isdrop: false,
      bread: false,
      phoneShow: false
    }
  },
  methods: {
    closeShow() {
      this.phoneShow = !this.phoneShow
    },
    handleScroll() {
      if (window.screen.width <= 860) {
        this.whiteStyle = false
      } else {
        this.whiteStyle = window.document.documentElement.scrollTop >= this.maxScroll
      }
    },
    clickItem(index) {
      switch (index) {
        case 0:
          this.to('index', '解决方案')
          break
        case 1:
          this.to('ex', '案例')
          break
        case 2:

          this.$track.webClick('登陆')
          window.open('https://www.huadongmeta.com/onlineark/index.html#/scene_manage')
          break
        case 3:
          this.to('download', '下载')
          break
        case 4:
          this.openUrl('price', '帮助中心')
          break
        case 5:
          this.to('cooperative', '代理合作')
          break
        case 6:
          this.to('self', '咨询')
          break
      }
    },
    openUrl(str, item) {
      this.$track.webClick(item)
      // eslint-disable-next-line no-undef
      if (typeof sensors !== 'undefined' && sensors !== null) {
        // eslint-disable-next-line no-undef
        sensors.track('h5_html', {
          // eslint-disable-next-line quote-props
          'htmlname': str
        })
      }
      if (str === 'arkWorld') {
        window.open('https://www.huadongmeta.com/onlineark/index.html#/hub/tCMT9xQX9TRbZ6sl')
      } else if (str === 'price') {
        window.open('https://www.wolai.com/2jSgh8ygUzCrdtjh5Cir2Y')
      } else if (str === 'about') {
        window.open('https://page.huadongmedia.com/h5/huadongmedia/index.html')
      }
    },
    to(str, item) {
      this.$track.webClick(item)
      // eslint-disable-next-line no-undef
      if (typeof sensors !== 'undefined' && sensors !== null) {
        // eslint-disable-next-line no-undef
        sensors.track('h5_html', {
          // eslint-disable-next-line quote-props
          'htmlname': str
        })
      }
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    },
    topage(str, item) {
      this.$track.webClick(item)
      // eslint-disable-next-line no-undef
      if (typeof sensors !== 'undefined' && sensors !== null) {
        // eslint-disable-next-line no-undef
        sensors.track('h5_html', {
          // eslint-disable-next-line quote-props
          'htmlname': str
        })
      }
      this.isdrop = false
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    },
    totpage() {
      this.$track.webClick('h5登陆')
      this.isdrop = false
      this.closeShow()
      // this.$emit('login')
    }
  }
}
</script>
<style scoped lang="scss">
.phone {
  display: none;
}
.board {
  display: flex;
  height: 67px;
  width: 100%;
  position: fixed;
  z-index: 11;
  top: 0px;
}

.shadow {
  z-index: 5;
  position: fixed;
  width: 100%;
  background: white;
  height: 67px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);
}

.nav {
  display: flex;
  height: 65px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1600px;
  position: fixed;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, 0);

  .img {
    width: 88.5px;
    height: 25px;
  }

  .first {
    left: 0%;
    font-size: 44px;
    color: #D9F9E5;
    appearance: none;
    font-weight: 800;
    line-height: 65px;
    user-select: none;
    margin-left: 30px;
    cursor: pointer;

    .logo {
      width: 161px;
      height: 44px;
      margin-top: 9px;
    }

    &:hover {
      color: #fff;
    }
  }

  .right {
    position: fixed;
    right: 0%;
    display: flex;
  }

  .item {
    width: max-content;
    color: white;
    height: 65px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 65px;
    margin: 0 20px;
    cursor: pointer;

    .indicator {
      position: absolute;
      width: 18px;
      bottom: 25%;
      background: white;
      height: 2.5px;
      border-radius: 2.5px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    i {
      color: rgba(206, 240, 254, 1);
      font-weight: 600;
      font-size: 15px;
    }

    &:hover {
      i {
        transform: rotate(180deg);
      }
    }

    &:hover {
      .drop {
        opacity: 1;
        height: 170px;
      }
    }

    &.on,
    &:hover {
      color: #D9F9E5;

      i {
        color: #D9F9E5;
      }
    }
  }

  .sli {
    display: none;
  }

  .dropp {
    display: none;
  }

  .ani {
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out 0ms;

    &:active {
      transform: scale(.9);
    }

    &:hover {
      // filter: brightness(100%);
      filter: brightness(150%);

      &:before,
      &:after {
        filter: brightness(150%);
      }
    }

    i {
      color: #fff;
      font-size: 20px;
      font-weight: 800;
    }

    &.gre {
      background: #D9F9E5;
      color: #252B4F;

      i {
        color: #252B4F;
      }

      &:hover {
        filter: brightness(120%);

        &:before,
        &:after {
          filter: brightness(120%);
        }
      }
    }

    &.ani {
      &:before {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all .5s ease-in-out;
        background-repeat: no-repeat;
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 20%, var(--ctaFirstAccent) 20%, transparent 30%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 10%, var(--ctaFirstAccent) 15%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
      }

      &.on,
      &:hover {
        &:before {
          display: block;
          -webkit-animation: topBubbles .75s ease-in-out forwards;
          animation: topBubbles .75s ease-in-out forwards;
        }
      }

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all .5s ease-in-out;
        background-repeat: no-repeat;
        display: none;
        bottom: -75%;
        background-image: radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 10%, var(--ctaFirstAccent) 15%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
      }

      &.on,
      &:hover {
        &:after {
          display: block;
          -webkit-animation: bottomBubbles .75s ease-in-out forwards;
          animation: bottomBubbles .75s ease-in-out forwards;
        }
      }
    }
  }
}

.nav-white {
  display: flex;
  height: 67px;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  max-width: 1600px;
  position: fixed;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, 0);

  .img {
    width: 88.5px;
    height: 25px;
    filter: drop-shadow(#22394E 0 60px);
    transform: translateY(-60px);
  }

  .first {
    left: 0%;
    font-size: 44px;
    color: #252B4F;
    appearance: none;
    line-height: 65px;
    user-select: none;
    margin-left: 30px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 800;
    font-family: Montserrat;

    .logo {
      width: 161px;
      height: 44px;
      margin-top: 9px;
      filter: drop-shadow(#22394E 0 60px);
      transform: translateY(-60px);
    }

    &:hover {
      color: #fff;
    }
  }

  .right {
    position: fixed;
    right: 0%;
    display: flex;
  }

  .item {
    width: max-content;
    color: #22394E;
    height: 65px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 65px;
    margin: 0 20px;
    cursor: pointer;

    .indicator {
      position: absolute;
      width: 18px;
      bottom: 25%;
      background: #22394E;
      height: 2.5px;
      border-radius: 2.5px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    i {
      color: rgba(206, 240, 254, 1);
      font-weight: 600;
      font-size: 15px;
    }

    &:hover {
      i {
        transform: rotate(180deg);
      }
    }

    &:hover {
      .drop {
        opacity: 1;
        height: 170px;
      }
    }

    &.on,
    &:hover {
      color: #22394E;

      i {
        color: #22394E;
      }
    }
  }

  .sli {
    display: none;
  }

  .dropp {
    display: none;
  }

  .ani {
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out 0ms;

    &:active {
      transform: scale(.9);
    }

    &:hover {
      filter: brightness(150%);

      &:before,
      &:after {
        filter: brightness(150%);
      }
    }

    i {
      color: #fff;
      font-size: 20px;
      font-weight: 800;
    }

    &.gre {
      background: #D9F9E5;
      color: #252B4F;

      i {
        color: #252B4F;
      }

      &:hover {
        filter: brightness(120%);

        &:before,
        &:after {
          filter: brightness(120%);
        }
      }
    }

    &.ani {
      &:before {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all .5s ease-in-out;
        background-repeat: no-repeat;
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 20%, var(--ctaFirstAccent) 20%, transparent 30%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 10%, var(--ctaFirstAccent) 15%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
      }

      &.on,
      &:hover {
        &:before {
          display: block;
          -webkit-animation: topBubbles .75s ease-in-out forwards;
          animation: topBubbles .75s ease-in-out forwards;
        }
      }

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all .5s ease-in-out;
        background-repeat: no-repeat;
        display: none;
        bottom: -75%;
        background-image: radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 10%, var(--ctaFirstAccent) 15%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
      }

      &.on,
      &:hover {
        &:after {
          display: block;
          -webkit-animation: bottomBubbles .75s ease-in-out forwards;
          animation: bottomBubbles .75s ease-in-out forwards;
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .phone {
      display: block;
    }
  .popUp{
    width: 308px;
    height: 224px;
    border-radius: 16px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-image: linear-gradient(126deg, #FFFFFF 0%, rgba(255,255,255,0.30) 54%, #FFFFFF 100%) 1;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);
    padding: 24px;
    position: fixed;
    top: 294px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    .close{
      width:12px;
      height: 12px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
    .popTop{
      font-family: 苹方-简;
      font-size: 16px;
      font-weight: bolder;
      line-height: 16px;
      text-align: center;
      text-transform: capitalize;
      letter-spacing: 0px;
      color: #3D3D3D;
      margin-bottom: 24px;
    }
    .popTitle{
      font-family: 苹方-简;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      text-transform: capitalize;
      letter-spacing: 0em;
      color: #22394E;
      font-family: 苹方-简;
      font-weight: bolder;
      font-size: 14;
      text-align: left;
    }
    .popText{
      font-family: 苹方-简;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      text-transform: capitalize;
      letter-spacing: 0em;
      color: #22394E;
      font-family: 苹方-简;
      font-weight: 400;
      font-size: 14;
      text-align: left;
      margin-bottom: 24px;
    }
    .popBtn{
      width: 112px;
      height: 40px;
      border-radius: 6px;
      background: #233057;
      font-family: 苹方-简;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0px;
      color: #D9F9E5;
      margin: 0 auto;
    }
  }
  .nav {
    // display: none;
    width: 100%;
    height: 60px;
    background: white;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    z-index: 1000;

    .first {
      flex: 1;
      text-align: left;
      position: relative;
      font-size: 24px;
      color: #22394E;
      z-index: 2;
      margin-left: 0;

      .logo {
        width: 80px;
        height: 22px;
        margin-top: 22px;
        filter: drop-shadow(#22394E 0 60px);
        transform: translateY(-60px);
      }
    }

    .item,
    .self {
      display: none;
    }

    .sli {
      width: 24px;
      height: 22px;
      display: block;
      position: relative;
      z-index: 2;
    }

    .dropp {
      position: fixed;
      z-index: 1;
      top: 60px;
      width: 100%;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      background: rgba(255, 255, 255, 0.95);
      height: 0;
      overflow: hidden;
      transition: all 0.3s ease;
      box-sizing: border-box;
      display: block;
      height: 332px;
      padding-top: 12px;
      transform: translate(100%, 0);

      &.on {
        transform: translate(-10px, 0);
        animation: drop 1s ease;
      }

      .dt {
        height: 0px;
        overflow: hidden;
        transition: all 0.3s ease;
      }

      .ll {
        width: 100%;
        margin: 0 auto;
        height: 1px;
        background: rgba(206, 240, 254, 1);
      }

      .d {
        height: 44px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-items: flex-start;
        font-size: 16px;
        font-weight: 600;
        color: #22394E;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: rgba(206, 240, 254, 1);
          font-size: 24px;
          margin-left: 10px;
          font-weight: bolder;
          text-shadow: 0 1px 0 rgba(206, 240, 254, 1);
          margin-right: -34px;
          transform-origin: 50% 50%;
        }

        &:hover {
          background: #DFDFDF;
          color: #252a50;
        }

        &.sel {
          position: absolute;
          z-index: 1;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100vw - 96px);
          height: 41px;
          background: #D9F9E5;
          border-radius: 8px;
          background: #D9F9E5;
          // padding: 0 20px;
          border-radius: 10px;
          font-size: 15px;
          font-weight: 600;
          color: #252B4F;
          // line-height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          box-sizing: border-box;
          left: 48px;
          bottom: 83px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
