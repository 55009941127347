import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

import Conf from '../views/conf.vue'
import Emplo from '../views/emplo.vue'
import Ex from '../views/ex.vue'
import Login from '../views/login.vue'
import Office from '../views/office.vue'
import Exhibition from '../views/exhibition.vue'
import Download from '../views/download.vue'
import Cooperative from '../views/cooperative.vue'
import H5form from '../views/h5form.vue'
import Self from '../views/self.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [{
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/conf',
    name: 'Conf',
    component: Conf
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/emplo',
    name: 'Emplo',
    component: Emplo
  },
  {
    path: '/h5form',
    name: 'H5form',
    component: H5form
  },
  {
    path: '/ex',
    name: 'Ex',
    component: Ex
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/office',
    name: 'Office',
    component: Office
  },
  {
    path: '/exhibition',
    name: 'Exhibition',
    component: Exhibition
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/cooperative',
    name: 'Cooperative',
    component: Cooperative
  },
  {
    path: '/self',
    name: 'Self',
    component: Self
  }
  ]
})

export default router
