<template>
  <div class="bottom">
    <div class="line"></div>
    <div class="rang">
        <div class="l">
          <div class="t">
            ARK
          </div>
          <div class="d">
            HUADONG TECH. 2012
            Web 3.0 时代先驱企业
            立足元宇宙，展望新未来
          </div>
          <div class="d">
            为了更仔细地看月亮<br/>
            多数人满足于透过望远镜所见之象
            但我们，想要登上月球
            伟大的成就从伟大的目标开始
          </div>
        </div>
        <div class="r">
          <div class="ul">
            <div class="t">
              产品
            </div>
            <a @click="to('office')">办公</a>
            <a @click="to('conf')">会议</a>
            <!-- <a href="">招聘</a> -->
          </div>
          <div class="ul">
            <div class="t">
              法律政策
            </div>
            <a target="_blank" href="https://www.huadongmeta.com/arkAgreement/privacy.html">隐私条款</a>
            <a target="_blank" href="https://www.huadongmeta.com/arkAgreement/service.html">用户协议</a>
            <a target="_blank" href="https://img.huadongmeta.com/ARKpaymentserviceagreement.pdf">付款协议</a>
          </div>
          <div class="ul">
            <div class="t">
              关于我们
            </div>
            <!-- <a target="_blank" href="https://page.huadongmedia.com/h5/huadongmedia/index.html">关于我们</a> -->
            <a @click="to('ex')">合作案例</a>
          </div>
        </div>
      </div>
      <div class="btmBtmBox">
        <span class="companyName">{{companyName}}</span> 网站名称： <span class="webName">{{webName}}</span> <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #D3D7E2;"
          class="recordNumber">{{recordNumber}}</a><br>
        公司地址：<span>上海市闵行区 宜山路2000号利丰广场2号楼2楼115室 </span> 电话：021-60443097<br>
        <div style="width:300px;margin:0 auto;" class="record"> <a target="_blank"
            :href='recordUrl'
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><!--img
              src="https://www.cashtoutiao.com/index/images/police.png" lazysrc="" style="float:left;width: 20px;" /-->
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#D3D7E2;">沪公网安备
              {{record}}号</p>
          </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottom',
  props: {
    msg: String
  },
  data () {
    return {
      recordNumber: null,
      companyName: null,
      webName: null,
      record: null,
      recordUrl: null
    }
  },
  mounted () {
    if (location.origin.indexOf('huadongmeta') >= 0) {
      this.recordNumber = '沪ICP备2022000367号－1'
      this.companyName = '上海与你信息科技有限公司'
      this.webName = 'ARK元宇宙办公协作平台'
      this.record = '31011202014100'
      this.recordUrl = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014100'
    } else {
      this.recordNumber = '沪ICP备2022019997号－1'
      this.companyName = '上海舟渊信息科技有限公司'
      this.webName = 'ark元宇宙官方网站'
      this.record = '31011202014407'
      this.recordUrl = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014407'
    }
  },
  methods: {
    to (str) {
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .btmBtmBox {
      width: 100%;
      background: #000;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff ;
      line-height: 28px;
      text-align: center;
      position: absolute;
      bottom: 0;

      span{
      color: #fff ;
      }
      div{
      color: #fff ;
      }

  }
.bottom {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: rgba(217, 249, 229, 1);
  width: 100%;
  height: 666px;
  box-sizing: border-box;
  .line {
    margin: 0 73px;
    width: calc(100% - 146px);
    height: 5px;
    background: #222C4D;
  }
  .rang {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 94px 0;
  }
  .l {
    text-align: left;
    flex: 1;
    .t {
      font-size: 30px;
      font-size: 30px;
      font-weight: 500;
      color: #222C4D;
      line-height: 37px;
    }
    .d {
      margin-top: 32px;
      font-size: 12px;
      color: #222C4D;
      line-height: 36px;
      white-space: pre;
    }
  }
  .r {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .ul {
      margin-left: 73px;
      .t {
        font-size: 16px;
        font-weight: 600;
        color: #222C4D;
        line-height: 70px;
      }
      a {
        margin-top: 8px;
        display: block;
        font-size: 12px;
        color: #222C4D;
        // line-height: 35px;
        line-height: 24px;
        margin-top: 14px;
        margin-bottom: 6px;
        text-decoration: none;
        cursor: pointer;
        // border-bottom: 2px solid transparent;
        // transition: all 0.3s ease;
        position: relative;
        z-index: 1;
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 1;
          left: 0;
          bottom: 0;
          transition: all 0.5s ease;
          height: 2px;
          background: #222C4D;
          width: 0;
        }
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1135px) {
  .bottom {
    // height: 340px;
    display: block;
    .line {
      margin: 0 10px;
      height: 2px;
      width: calc(100% - 20px);
      margin-bottom: 30px;
    }
    .rang {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      display: block;
      .l {
        .t {
          font-size: 13px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #222C4D;
          // line-height: 16px;
        }
        .d {
          font-size: 8px;
          font-family: HelveticaNeue;
          color: #222C4D;
          // line-height: 12px;
          white-space: inherit;
          margin-top: 0;
        }
      }
      .r {
        justify-content: space-between;
        .ul {
          margin-left: 10px;
          .t {
            font-size: 8px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #222C4D;
            line-height: 30px;
            margin-bottom: 10px;
          }
          a {
            font-size: 8px;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #222C4D;
            line-height: 24px;
            margin: 0;
          }
        }
      }
    }
  }

  .btmBtmBox {
      width: 100%;
      background: #000;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff ;
      line-height: 28px;
      text-align: center;
      // position: inherit;
      height: 260px;
      margin-top: 45px;
      display: flex;
      flex-direction: column;

      span{
      color: #fff ;
      }
      div{
      color: #fff ;
      }

  }
}
</style>
