const trackEvent = (catagory, action, label, value) => {
    if (window._czc) {
        let _action = action || ''
        _action = _action + 'platformType=' + (window.IS_PC ? 'Web' : 'H5')
        let trackInfo = ['_trackEvent', catagory, _action]
        if (label) {
            trackInfo.push(label)
        }
        if (value) {
            trackInfo.push(value)
        }
        _czc.push(trackInfo)
    }
}


const webClick = (name) => {
    if (!name) {
        return
    }
    trackEvent('$webclick', '$element_name=' + name)
}

const reportEvent = (evetName, config = {}, extraInfo = {}) => {
    if (evetName) {
        trackEvent(evetName, decodeURI(new URLSearchParams(config).toString()), extraInfo.label, extraInfo.value)
    }
}

export default {
    webClick,
    reportEvent
}