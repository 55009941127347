<template>
  <div class="contactinfo">
    <div class="l">
      <div class="d">
        商务合作: business@huadongmeta.com
      </div>
      <div class="d">
        招聘邮箱: recruit@huadongmeta.com
      </div>
    </div>
    <div class="r">
      <bbtn txt="查看更多ARK最新资讯"
        @click="to('ex')"></bbtn>
      <img src="/img/code.jpeg" />
    </div>
  </div>
</template>

<script>
import bbutton from './button.vue'

export default {
  name: 'contactinfo',
  props: {
    msg: String
  },
  components: {
    bbtn: bbutton
  },
  methods: {
    to (str) {
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contactinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  .l {
    flex: 1;
    .d {
      font-size: 16px;
      font-weight: 600;
      color: #222C4D;
      line-height: 45px;
      text-align: left;
    }
  }
  .r {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      width: 224px;
      height: 60px;
      font-size: 16px;
      font-weight: 600;
      color: #D7F0E1;
      /deep/ i {
        color: #D7F0E1;
      }
    }
    img {
      display: block;
      width: 235px;
      height: 235px;
      margin-left: 38px;
    }
  }
}
@media screen and (max-width: 860px) {
  .contactinfo {
    flex-direction: column;
    .l {
      .d {
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222C4D;
        line-height: 20px;
      }
    }
    .r {
      flex-direction: column;
      .button {
        margin-top: 20px;
      }
      img {
        margin-top: 18px;
        margin-left: 0;
        width: 180px;
        height: 180px;
      }
    }
  }
}
</style>
