
function trackEvent(evetName, config) {
    if (typeof sensors != "undefined" && sensors != null) {
        sensors.track(evetName, config)
    }
}

function uncaught(id, from, error, hook) {
    login()
    trackEvent("web_uncaught_error", {
        "from": from,
        "errorText": error,
        "hook": hook,
        "errorTime": new Date().getTime(),
        "userId": id,
        // "ip": returnCitySN['cip'],
        // "cname": returnCitySN['cname'],
        "userAgent": navigator.userAgent,
        "appName": navigator.appName,
        "platform": navigator.platform
    })
}

function webClick(name) {
    if (typeof sensors != "undefined" && sensors != null) {
        login()
        sensors.track('$WebClick', {
            $element_name: name
        })
    }
}

function activeReport(id, error, from) {
    login()
    trackEvent("web_active_report_error", {
        "from": from,
        "errorText": error,
        "errorTime": new Date().getTime(),
        "userId": id,
        // "ip": returnCitySN['cip'],
        // "cname": returnCitySN['cname'],
        "userAgent": navigator.userAgent,
        "appName": navigator.appName,
        "platform": navigator.platform,
    })
}

var loginUser;

function login() {
    // const userId = storage.getUserId()
    // if (!userId || loginUser === userId) {
    //     return
    // }
    // if (typeof sensors != "undefined" && sensors != null) {
    //     sensors.login(userId)
    //     loginUser = userId
    // }
}

function logout() {
    if (typeof sensors != "undefined" && sensors != null) {
        sensors.logout()
        loginUser = null
    }
}

function reportEvent(evetName, config) {
    if (!evetName || !config) {
        return
    }
    login()
    trackEvent(evetName, config);
}

export default {
    uncaught,
    activeReport,
    webClick,
    login,
    logout,
    reportEvent,
}