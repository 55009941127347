<template>
  <div class="button ani"
    :style="txt==='了解更多'?'background:#d9f9e4;border:1px #242c4e solid':''"
    :class="{gre: type === 'gre'}"
    @click="$emit('click')">
    <img class="iosImg" v-if="type === 'gre' && txt === 'iOS'" src="./../../public/img/ios.png" alt="">
    <img class="iosImg" v-if="type === 'gre' && txt === 'Android'" src="./../../public/img/android.png" alt="">
    <img class="iosImg" v-if="txt === 'iOS下载'" src="./../../public/img/ios1.png" alt="">
    <img class="iosImg" v-if="txt === 'Android下载'" src="./../../public/img/android1.png" alt="">
    <a :name="txt" :style="type === 'gre'||txt==='了解更多'?'':'color:#d9f9e4'">{{txt}}</a><i class="el-icon-arrow-right" v-if="!hide&&txt!='去体验'" :style="txt==='了解更多'?'color:#242c4e':''"></i>
  </div>
</template>

<script>
export default {
  name: 'bbutton',
  props: ['txt', 'type', 'hide', 'btn1']
}
</script>

<style scoped lang="scss">
.ani {
  --ctaFirstAccent:#242c50;
  &.gre {
    --ctaFirstAccent:#d9f9e4;
  }
}
.button {
  background: #242c4e;
  border-radius: 12px;
  cursor: pointer;
  // width: 200px;
  box-sizing: border-box;
  color: #fff;
  // font-size: 12px;
  width: 180px;
  height: 45px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out 0ms;
  .iosImg{
    width: 18px !important;
    height: 21.82px !important;
    margin-right: 10px;
  }
  &:active {
    transform: scale(.9);
  }
  &:hover {
    // filter: brightness(200%);
      // filter: brightness(100%);
    filter: brightness(150%);
    // color: #fff;
    &:before,
    &:after {
      filter: brightness(150%);
    }
  }
  i {
    color: #fff;
    font-size: 20px;
    font-weight: 800;
  }
  &.gre {
    background: #D9F9E5;
    color: #252B4F;
    i {
      color: #252B4F;
    }
    &:hover {
      // filter: brightness(100%);
      filter: brightness(120%);
      &:before,
      &:after {
        filter: brightness(120%);
      }
    }
  }
  &.ani {
    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 140%;
      height: 100%;
      left: -20%;
      z-index: -1000;
      transition: all .5s ease-in-out;
      background-repeat: no-repeat;
      display: none;
      top: -75%;
      background-image: radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,transparent 20%,var(--ctaFirstAccent) 20%,transparent 30%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,transparent 10%,var(--ctaFirstAccent) 15%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%);
      background-size: 10% 10%,20% 20%,15% 15%,20% 20%,18% 18%,10% 10%,15% 15%,10% 10%,18% 18%;
    }
    &.on,
    &:hover {
      &:before {
        display: block;
        -webkit-animation: topBubbles .75s ease-in-out forwards;
        animation: topBubbles .75s ease-in-out forwards;
      }
    }
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 140%;
      height: 100%;
      left: -20%;
      z-index: -1000;
      transition: all .5s ease-in-out;
      background-repeat: no-repeat;
      display: none;
      bottom: -75%;
      background-image: radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,transparent 10%,var(--ctaFirstAccent) 15%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%),radial-gradient(circle,var(--ctaFirstAccent) 20%,transparent 20%);
      background-size: 15% 15%,20% 20%,18% 18%,20% 20%,15% 15%,10% 10%,20% 20%;
    }
    &.on,
    &:hover {
      &:after {
        display: block;
          -webkit-animation: bottomBubbles .75s ease-in-out forwards;
          animation: bottomBubbles .75s ease-in-out forwards;
        }
      }
  }

}

@media screen and (max-width: 860px) {
  .button {
    width: 300px !important;
    height: 50px !important;
    font-size: 10px  !important;
    line-height: 14px  !important;
    border-radius: 8px  !important;
    margin: 15px 0 !important;
  }
}
</style>
