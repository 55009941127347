var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('router-view',{on:{"visi":function($event){_vm.visipop = true}}}),(_vm.visipop)?_c('div',{staticClass:"mask",on:{"click":function($event){_vm.visipop = false}}},[_c('div',{staticClass:"contentbox",on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"el-icon-close blue closeImg",on:{"click":function($event){_vm.visipop = false}}}),_c('div',{staticClass:"ti"},[_vm._v(" 选择你想体验的元宇宙场景 ")]),_c('div',{staticClass:"listli"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"slideout"},[_c('div',{staticClass:"slidein",style:({
                  transform: `translate(-${_vm.offi * (_vm.isphone ? 240 : 316)}px)`
                })},[_c('img',{attrs:{"src":"/img/loginoff1.png"}}),_c('img',{attrs:{"src":"/img/loginoff2.png"}}),_c('img',{attrs:{"src":"/img/loginoff3.png"}}),_c('img',{attrs:{"src":"/img/loginoff4.png"}}),_c('img',{attrs:{"src":"/img/loginoff5.png"}})])]),_c('div',{staticClass:"points"},_vm._l(([1,2,3,4,5]),function(i,k){return _c('div',{key:k,class:{on: _vm.offi === k},on:{"click":function($event){_vm.offi = k}}})}),0),_c('div',{staticClass:"btn anibtn ani",on:{"click":function($event){return _vm.openUrl(1)}}},[_vm._v(" 办公 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"slideout"},[_c('div',{staticClass:"slidein",style:({
                  transform: `translate(-${_vm.confi * (_vm.isphone ? 240 : 316)}px)`
                })},[_c('img',{attrs:{"src":"/img/loginconf1.png"}}),_c('img',{attrs:{"src":"/img/loginconf2.png"}}),_c('img',{attrs:{"src":"/img/loginconf3.png"}}),_c('img',{attrs:{"src":"/img/loginconf4.png"}}),_c('img',{attrs:{"src":"/img/loginconf5.png"}})])]),_c('div',{staticClass:"points"},_vm._l(([1,2,3,4,5]),function(i,k){return _c('div',{key:k,class:{on: _vm.confi === k},on:{"click":function($event){_vm.confi = k}}})}),0),_c('div',{staticClass:"btn anibtn ani",on:{"click":function($event){return _vm.openUrl(2)}}},[_vm._v(" 会议 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"slideout"},[_c('div',{staticClass:"slidein",style:({
                  transform: `translate(-${_vm.tt * (_vm.isphone ? 240 : 316)}px)`
                })},[_c('img',{attrs:{"src":"/img/zhdlImg.jpg"}}),_c('img',{attrs:{"src":"/img/zhdlImg2.jpg"}}),_c('img',{attrs:{"src":"/img/zhdlImg3.jpg"}}),_c('img',{attrs:{"src":"/img/zhdlImg4.jpg"}}),_c('img',{attrs:{"src":"/img/zhdlImg5.jpg"}})])]),_c('div',{staticClass:"points"},_vm._l(([1,2,3,4,5]),function(i,k){return _c('div',{key:k,class:{on: _vm.tt === k},on:{"click":function($event){_vm.tt = k}}})}),0),_c('div',{staticClass:"btn anibtn ani",on:{"click":function($event){return _vm.goUlr('exhibition')}}},[_vm._v(" 展会 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"slideout"},[_c('div',{staticClass:"slidein",style:({
                  transform: `translate(-${_vm.tt * (_vm.isphone ? 240 : 316)}px)`
                })},[_c('img',{attrs:{"src":"/img/zykjImg.jpg"}}),_c('img',{attrs:{"src":"/img/zykjImg2.jpg"}}),_c('img',{attrs:{"src":"/img/zykjImg3.jpg"}}),_c('img',{attrs:{"src":"/img/zykjImg4.jpg"}}),_c('img',{attrs:{"src":"/img/zykjImg5.jpg"}})])]),_c('div',{staticClass:"points"},_vm._l(([1,2,3,4,5]),function(i,k){return _c('div',{key:k,class:{on: _vm.tt === k},on:{"click":function($event){_vm.tt = k}}})}),0),_c('div',{staticClass:"btn anibtn ani",on:{"click":function($event){return _vm.openUrl(3)}}},[_vm._v(" 自由空间 ")])])])])]):_vm._e(),_c('officialAccount',{ref:"child"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }