<template>
  <div id="app">
    <router-view @visi="visipop = true" />
    <div v-if="visipop" class="mask" @click="visipop = false">
      <div class="contentbox" @click.stop>
        <i class="el-icon-close blue closeImg" @click="visipop = false" />
        <div class="ti">
          选择你想体验的元宇宙场景
        </div>
        <div class="listli">
          <div class="item">
            <div class="slideout">
              <div class="slidein" :style="{
                  transform: `translate(-${offi * (isphone ? 240 : 316)}px)`
                }">
                <img src="/img/loginoff1.png" />
                <img src="/img/loginoff2.png" />
                <img src="/img/loginoff3.png" />
                <img src="/img/loginoff4.png" />
                <img src="/img/loginoff5.png" />
              </div>
            </div>
            <div class="points">
              <div :class="{on: offi === k}" v-for="(i, k) in [1,2,3,4,5]" @click="offi = k" :key="k" />
            </div>
            <div class="btn anibtn ani" @click="openUrl(1)">
              办公
            </div>
          </div>
          <div class="item">
            <div class="slideout">
              <div class="slidein" :style="{
                  transform: `translate(-${confi * (isphone ? 240 : 316)}px)`
                }">
                <img src="/img/loginconf1.png" />
                <img src="/img/loginconf2.png" />
                <img src="/img/loginconf3.png" />
                <img src="/img/loginconf4.png" />
                <img src="/img/loginconf5.png" />
              </div>
            </div>
            <div class="points">
              <div :class="{on: confi === k}" v-for="(i, k) in [1,2,3,4,5]" @click="confi = k" :key="k" />
            </div>
            <div class="btn  anibtn ani" @click="openUrl(2)">
              会议
            </div>
          </div>
          <div class="item">
            <div class="slideout">
              <div class="slidein" :style="{
                  transform: `translate(-${tt * (isphone ? 240 : 316)}px)`
                }">
                <img src="/img/zhdlImg.jpg" />
                <img src="/img/zhdlImg2.jpg" />
                <img src="/img/zhdlImg3.jpg" />
                <img src="/img/zhdlImg4.jpg" />
                <img src="/img/zhdlImg5.jpg" />
              </div>
            </div>
            <div class="points">
              <div :class="{on: tt === k}" v-for="(i, k) in [1,2,3,4,5]" @click="tt = k" :key="k" />
            </div>
            <!-- <div class="btn  anibtn ani dis">
              敬请期待
            </div> -->
            <div class="btn anibtn ani" @click="goUlr('exhibition')">
              展会
            </div>
          </div>
          <div class="item">
            <div class="slideout">
              <div class="slidein" :style="{
                  transform: `translate(-${tt * (isphone ? 240 : 316)}px)`
                }">
                <img src="/img/zykjImg.jpg" />
                <img src="/img/zykjImg2.jpg" />
                <img src="/img/zykjImg3.jpg" />
                <img src="/img/zykjImg4.jpg" />
                <img src="/img/zykjImg5.jpg" />
              </div>
            </div>
            <div class="points">
              <div :class="{on: tt === k}" v-for="(i, k) in [1,2,3,4,5]" @click="tt = k" :key="k" />
            </div>
            <!-- <div class="btn  anibtn ani dis">
              敬请期待
            </div> -->
            <div class="btn anibtn ani" @click="openUrl(3)">
              自由空间
            </div>
          </div>
          <!-- <div class="item">
            <img src="" />
            <div class="btn">
              招聘
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <officialAccount ref="child"/>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import officialAccount from './components/officialAccount.vue'

export default {
  data () {
    return {
      visipop: false,
      offi: 0,
      confi: 0,
      tt: 0,
      officeUrl: null,
      meetingUrl: null,
      flexspace: null
    }
  },
  components: {
    officialAccount
  },
  computed: {
    ...mapState(['isphone'])
  },
  mounted () {
    var dataNum
    if (location.search === '') {
      dataNum = '?'
    } else {
      dataNum = location.search
    }
    this.officeUrl = 'https://www.huadongmeta.com/onlineark/index.html#/office' + dataNum
    this.meetingUrl = 'https://www.huadongmeta.com/onlineark/index.html#/meeting' + dataNum
    this.flexspace = 'https://www.huadongmeta.com/onlineark/index.html#/flexspace' + dataNum
    window.addEventListener('resize', this.resize)
    this.resize()
    // if (window.innerWidth > 600) {
    setInterval(v => {
      if (this.offi < 4) {
        this.offi = this.offi + 1
      } else {
        this.offi = 0
      }
      if (this.confi < 4) {
        this.confi = this.confi + 1
      } else {
        this.confi = 0
      }
      if (this.tt < 3) {
        this.tt = this.tt + 1
      } else {
        this.tt = 0
      }
    }, 3000)
    // }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    ...mapMutations(['setisphone', 'setwidthmore', 'setwidthmorelogin', 'setfulltrans']),
    resize () {
      if (window.innerWidth > 600) {
        this.setisphone(false)
      } else {
        this.setisphone(true)
      }
      if ((window.innerWidth / window.innerHeight) > (1920 / 1080)) {
        this.setwidthmore(true)
      } else {
        this.setwidthmore(false)
      }
      if (((window.innerWidth - 640) / window.innerHeight) > (1920 / 1080)) {
        this.setwidthmorelogin(true)
      } else {
        this.setwidthmorelogin(false)
      }
      if ((window.innerWidth / window.innerHeight) > (1920 / 1080)) {
        const rate = (window.innerWidth / window.innerHeight) / (1920 / 1080)
        this.setfulltrans(`${rate}, 1`)
      } else {
        const rate = (window.innerHeight / window.innerWidth) / (1080 / 1920)
        this.setfulltrans(`1, ${rate}`)
      }
    },
    goUlr (str) {
      this.$router.push({
        path: './' + str
      })
      this.visipop = false
    },
    openUrl (item) {
      if (item === 1) {
        window.open(this.officeUrl)
      } else if (item === 2) {
        window.open(this.meetingUrl)
      } else if (item === 3) {
        window.open(this.flexspace)
      }
      this.visipop = false
    }
    // handleClick () {
    //   this.$refs.child.sing()
    // }
  }
}
</script>

<style lang="scss">
  @import "./index.css";

  .closeImg{
    position: absolute !important;
    top: 16px !important;
    right: 16px !important;
    left: unset !important;
  }

  * {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #222C4D;
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 12px;
  }

  .rang {
    width: 1040px;
    overflow: hidden;
    margin: 0 auto;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .ani {
    --ctaFirstAccent: #242c50;

    &.gre {
      --ctaFirstAccent: #d9f9e4;
    }
  }

  .anibtn {
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out 0ms;

    &:active {
      transform: scale(.9);
    }

    &:hover {
      // filter: brightness(100%);
      filter: brightness(150%);

      &:before,
      &:after {
        filter: brightness(150%);
      }
    }

    &.gre {
      background: #D9F9E5;
      color: #252B4F;

      i {
        color: #252B4F;
      }

      &:hover {
        // filter: brightness(100%);
        filter: brightness(120%);

        &:before,
        &:after {
          filter: brightness(120%);
        }
      }
    }

    &.ani {
      &:before {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all .5s ease-in-out;
        background-repeat: no-repeat;
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 20%, var(--ctaFirstAccent) 20%, transparent 30%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 10%, var(--ctaFirstAccent) 15%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
      }

      &.on,
      &:hover {
        &:before {
          display: block;
          -webkit-animation: topBubbles .75s ease-in-out forwards;
          animation: topBubbles .75s ease-in-out forwards;
        }
      }

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all .5s ease-in-out;
        background-repeat: no-repeat;
        display: none;
        bottom: -75%;
        background-image: radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, transparent 10%, var(--ctaFirstAccent) 15%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%), radial-gradient(circle, var(--ctaFirstAccent) 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
      }

      &.on,
      &:hover {
        &:after {
          display: block;
          -webkit-animation: bottomBubbles .75s ease-in-out forwards;
          animation: bottomBubbles .75s ease-in-out forwards;
        }
      }
    }

  }

  .mask {

    // width: 100%;
    // height: 100%;
    &:nth-child(2) {
      z-index: 999;
    }

    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    video {
      width: 1200px;
      height: 733px;
      background: #000000;
      position: absolute;
      z-index: 10001;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    i {
      color: #fff;
      position: absolute;
      z-index: 2;
      left: calc(50% + 600px - 20px);
      top: calc(50% - 366px - 20px);
      font-size: 20px;
      cursor: pointer;

      &.blue {
        color: rgba(38, 48, 95, 1);
        left: calc(50% + 600px - 30px);
        top: calc(50% - 232px + 10px);
      }
    }
  }

  .contentbox {
    // width: 1200px;
    // height: 465px;
    width: 720px;
    height: 594px;
    background: #D9F9E5;
    // border: 5px solid #252B4F;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .ti {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #3D3D3D;
      margin-top: 40px;
      margin-bottom: 32px;
    }

    .listli {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: wrap;
      justify-content:space-evenly;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 3px;

        .slideout {
          width: 316px;
          height: 178px;
          overflow: hidden;

          .slidein {
            width: calc(316px * 5);
            height: 178px;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }

        .points {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: -14px;

          >div {
            width: 7px;
            height: 7px;
            border-radius: 7px;
            background-color: #222C4D;
            margin: 0 3px;
            cursor: pointer;

            &.on {
              background: #fff;
            }
          }
        }

        img {
          display: block;
          width: 316px;
          height: 178px;
          border-radius: 3px;
        }

        .btn {
          width: 84px;
          height: 29px;
          border-radius: 3px;
          background: #252B50;
          font-size: 13px;
          font-weight: 500;
          line-height: 29px;
          text-align: center;
          color: #D9F9E5;
          font-family: PingFangSC-Semibold, PingFang SC;
          color: #D9F9E5;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 7px;
          margin-bottom: 32px;
          cursor: pointer;

          &.dis {
            background: transparent;
            border: 2px solid #252B4F;
            cursor: no-drop;
            color: #252B4F;
          }
        }

        &:not(:last-child) {
          // margin-right: 46px;
        }
      }
    }
  }

  @media screen and (max-width: 860px) {
    #app {
      width: 100vw;
      overflow-x: hidden;
    }

    .mask {
      i {
        right: 10px;
        left: unset;
        display: block;
        width: 20px;
        top: 10px;
        z-index: 10002;
        color: #fff;
      }

      video {
        width: 100vw;
        height: 100vh;
      }
    }

    .contentbox {
      width: 100vw;
      height: 100vh;
      background: #252B4F;
      border-radius: 0;
      box-sizing: border-box;
      padding-top: 70px;

      // padding-top: 130px;
      i {
        top: 10px !important;
        right: 10px;
        width: 20px;
        color: #fff !important;
        left: unset !important;
      }

      .ti {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
      }

      .listli {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-flow: wrap;
        overflow-y: auto;

        .item {
          margin: 0 !important;
          position: relative;
          margin-bottom: 20px !important;

          .points {
            display: none;
          }

          .slideout {
            width: 240px;
            height: 135px;
            background: rgba(0, 0, 0, 0.6000);

            .slidein {
              height: 135px;

              img {
                width: 240px;
                height: 135px;
              }
            }
          }

          .btn {
            // display: none;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6000);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 22px;
            border-radius: 0;
            margin: 0;

            &.dis {
              background: transparent;
              // border: 2px solid #252B4F;
              border: none;
              cursor: no-drop;
              color: #ccc;
              background: rgba(0, 0, 0, 0.8);
            }
          }
        }
      }
    }
  }

</style>
