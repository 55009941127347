<template>
  <div class="cooperative">
    <navlist :curIndex="5" @login="$emit('visi')" />
    <div class="pc">
      <div class="topBox">
        <img src="./../../public/img/cooperative.png" alt="">
        <div class="topTitle">
          <div class="titleName">成为ARK元宇宙</div>
          <div class="titleName2 titleName">代理合作伙伴</div>
          <div class="btn" @click="formShow">立即加入</div>
        </div>
      </div>
      <div class="pagebox">
        <div class="pageCenter">
          <div class="pageCenterTitle">ARK元宇宙为代理合作伙伴提供<br />更具 <span>创造力、自由度、商机转化效益</span> 的合作</div>
          <div class="pageCenterTitle2">基于此，我们提供Power-Up权益和服务，助力共同的元宇宙新业务拓展、无限增长！</div>
          <div class="pageCenterBox">
            <div class="titleBox">
              <img src="./../../public/img/Pencil.png" alt="">
              <div class="titleName">培训计划</div>
              <div class="btmText">整套ARK元宇宙资源包、免费参加内部网络研讨会、提供远程工作指南，等独家专业完整内部培训计划，加速实现专业独立完成客户转化、项目运营增长。</div>
            </div>
            <div class="titleBox titleBox2">
              <img src="./../../public/img/Share.png" alt="">
              <div class="titleName">共享全平台传播素材</div>
              <div class="btmText btmText2">
                品牌官网落地页内容直接接入/复制（协助技术团队支持），0门槛同步上线宣传，ARK元宇宙产品2023全年市场营销推广协助，无论广告投放还是活动营销，扩建品牌影响力，助力代理合作伙伴更多商机</div>
            </div>
            <div class="titleBox">
              <img src="./../../public/img/Security.png" alt="">
              <div class="titleName">2个月商机保护</div>
              <div class="btmText">每2个月可更新客户线索，提供全面的客户线索保护</div>
            </div>
          </div>
          <div class="pageCenterBox">
            <div class="titleBox">
              <img src="./../../public/img/Folder.png" alt="">
              <div class="titleName">优先看板方案支持</div>
              <div class="btmText">结合伙伴所处的行业赛道特征，ARK元宇宙将优先与代理合作伙伴围绕元宇宙领域提供专业的咨询和解决方案规划，形成灵活方案看板，以便更好地为客户服务</div>
            </div>
            <div class="titleBox titleBox2">
              <img src="./../../public/img/Profile.png" alt="">
              <div class="titleName">享受自主报价</div>
              <div class="btmText btmText2">
                依照伙伴自己运营项目方式决定，拥有自主报价权益，我们支持您随时随地以自己的方式开启业务<br /><span>（若您希望直接简单地获得赢单的返点，请在申请表中填入您的选择及说明，另有商务人员和您联系）</span>
              </div>
            </div>
            <div class="titleBox">
              <img src="./../../public/img/Suitcase.png" alt="">
              <div class="titleName">全时高标准运维支持</div>
              <div class="btmText">ARK元宇宙为代理合作伙伴提供切实可行的、体系化的培训服务，让我们的代理合作伙伴能够独立帮客户完成项目运维；同时ARK迭代同步至代理合作伙伴辅助获取最新商机</div>
            </div>
          </div>
          <div class="pageCenterBox">
            <div class="titleBox">
              <img src="./../../public/img/Messages.png" alt="">
              <div class="titleName">赢单10%返回奖励</div>
              <div class="btmText">若伙伴2023年度累计成交赢单金额超过200w，或超过200w的，将获最高10%的累计赢单金额返回奖励，鼓励更大飞跃</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagebox2 pagebox">
        <div>招募“MSCP”代理合作伙伴计划，实现元宇宙丰富商机</div>
        <img src="./../../public/img/MSCP.png" alt="">
      </div>
      <div class="jobPush">加入流程</div>
      <img class="jobPushImg" src="./../../public/img/jobPush.png" alt="">
      <div class="jobPushBtn" @click="formShow">现在加入</div>
    </div>
    <div class="h5">
      <div class="h5topBox">
        <img src="./../../public/img/cooperative.png" alt="">
        <div class="h5topTitle">
          <div class="h5titleName">成为ARK元宇宙</div>
          <div class="h5titleName2 h5titleName">代理合作伙伴</div>
          <div class="h5btn" @click="to('h5form')">立即加入</div>
        </div>
      </div>
      <div class="h5NameTop">
        ARK元宇宙为代理合作伙伴提供更具<br />
        <span>创造力、自由度、商机转化效益</span>的合作
      </div>
      <div class="h5NameTopText">基于此，我们提供Power-Up权益和服务，助力共同的元宇宙新业<br />务拓展、无限增长！</div>
      <div class="h5BoxTitle">
        <div class="h5Box">
          <img src="./../../public/img/Pencil.png">
          <div class="h5BoxName">培训计划</div>
          <div class="h5BoxText">整套ARK元宇宙资源包、免费参加内部网络研讨会、提供远程工作指南，等独家专业完整内部培训计划，加速实现专业独立完成客户转化、项目运营增长。</div>
        </div>
        <div class="h5Box">
          <img src="./../../public/img/Share.png">
          <div class="h5BoxName">共享全平台传播素材</div>
          <div class="h5BoxText">
            品牌官网落地页内容直接接入/复制（协助技术团队支持），0门槛同步上线宣传，ARK元宇宙产品2023全年市场营销推广协助，无论广告投放还是活动营销，扩建品牌影响力，助力代理合作伙伴更多商机</div>
        </div>
      </div>
      <div class="h5BoxTitle">
        <div class="h5Box">
          <img src="./../../public/img/Security.png">
          <div class="h5BoxName">2个月商机保护</div>
          <div class="h5BoxText">每2个月可更新客户线索，提供全面的客户线索保护</div>
        </div>
        <div class="h5Box">
          <img src="./../../public/img/Folder.png">
          <div class="h5BoxName">优先看板方案支持</div>
          <div class="h5BoxText">结合伙伴所处的行业赛道特征，ARK元宇宙将优先与代理合作伙伴围绕元宇宙领域提供专业的咨询和解决方案规划，形成灵活方案看板，以便更好地为客户服务</div>
        </div>
      </div>
      <div class="h5BoxTitle">
        <div class="h5Box">
          <img src="./../../public/img/Profile.png">
          <div class="h5BoxName">享受自主报价</div>
          <div class="h5BoxText">依照伙伴自己运营项目方式决定，拥有自主报价权益，我们支持您随时随地以自己的方式开启业务<br />
            （若您希望直接简单地获得赢单的返点，请在申请表中填入您的选择及说明，另有商务人员和您联系）</div>
        </div>
        <div class="h5Box">
          <img src="./../../public/img/Suitcase.png">
          <div class="h5BoxName">全时高标准运维支持</div>
          <div class="h5BoxText">ARK元宇宙为代理合作伙伴提供切实可行的、体系化的培训服务，让我们的代理合作伙伴能够独立帮客户完成项目运维；同时ARK迭代同步至代理合作伙伴辅助获取最新商机</div>
        </div>
      </div>
      <div class="h5BoxTitle">
        <div class="h5Box">
          <img src="./../../public/img/Messages.png">
          <div class="h5BoxName">赢单10%返回奖励</div>
          <div class="h5BoxText">若伙伴2023年度累计成交赢单金额超过200w，或超过200w的，将获最高10%的累计赢单金额返回奖励，鼓励更大飞跃</div>
        </div>
        <div class="h5Box">
          <div class="h5BoxName"></div>
          <div class="h5BoxText"></div>
        </div>
      </div>
      <div class="h5mscp">
        <div>招募“MSCP”代理伙伴合作计划<br />
          实现元宇宙丰富商机</div>
        <img src="./../../public/img/mscph5.png">
      </div>
      <img class="h5jobPushImg" src="./../../public/img/h5jobPush.png" alt="">
      <div class="h5jobPushBtn" @click="to('h5form')">立刻加入</div>
    </div>
    <div v-if="formState" class="formBoxBg">
      <div class="formBox" v-if="userFormBox">
        <img src="./../../public/img/close.png" class="close" @click="formShow">
        <div class="formInput">
          <div class="inputBox">
            <div class="inputTitle">
              <span>*</span>
              您的称呼
            </div>
            <input class="input" placeholder="请告知我们如何称呼您" v-model='user_name'>
            <div class="error" v-if="errorShow && user_name == ''">您的称呼不能为空</div>
          </div>
          <div class="inputBox">
            <div class="inputTitle">
              <span>*</span>
              您的电话
            </div>
            <input class="input" placeholder='请填写您的联系电话' maxlength="11" v-model='mobile'>
            <div class="error" v-if="errorShow && (mobile == '' || mobile.length < 11)">您的电话格式错误</div>
          </div>
        </div>
        <div class="formInput">
          <div class="inputBox">
            <div class="inputTitle">
              <span>*</span>
              组织/公司
            </div>
            <input class="input" placeholder="请填写您的组织或公司全称" v-model='corp_name'>
            <div class="error" v-if="errorShow && corp_name == ''">组织/公司不能为空</div>
          </div>
          <div class="inputBox">
            <div class="inputTitle">
              <span>*</span>
              职位
            </div>
            <input class="input" placeholder='请填写您的职位' v-model='position'>
            <div class="error" v-if="errorShow && position == ''">职位不能为空</div>
          </div>
        </div>
        <div class="formInput">
          <div class="inputBox">
            <div class="inputTitle">
              <span>*</span>
              伙伴类型
            </div>
            <select class="input" style="padding:0 16px;" v-model='huobanleixing'>
              <option selected disabled hidden value="">请选择您期望成为我们伙伴的类型</option>
              <option value="M分销伙伴">M 分销伙伴</option>
              <option value="S服务伙伴">S 服务伙伴</option>
              <option value="C内容伙伴">C 内容伙伴</option>
              <option value="P集成伙伴">P 集成伙伴</option>
            </select>
            <div class="error" v-if="errorShow && huobanleixing == ''">伙伴类型不能为空</div>
          </div>
          <div class="inputBox">
            <div class="inputTitle">
              <span>*</span>
              省份
            </div>
            <select class="input" style="padding:0 16px;" v-model='region'>
              <option selected disabled hidden value="">省</option>
              <option value="北京">北京</option>
              <option value="天津">天津</option>
              <option value="河北省">河北省</option>
              <option value="山西省">山西省</option>
              <option value="内蒙古自治区">内蒙古自治区</option>
              <option value="辽宁省">辽宁省</option>
              <option value="吉林省">吉林省</option>
              <option value="黑龙江省">黑龙江省</option>
              <option value="上海">上海</option>
              <option value="江苏省">江苏省</option>
              <option value="江苏省">浙江省</option>
              <option value="安徽省">安徽省</option>
              <option value="福建省">福建省</option>
              <option value="江西省">江西省</option>
              <option value="山东省">山东省</option>
              <option value="河南省">河南省</option>
              <option value="湖北省">湖北省</option>
              <option value="湖南省">湖南省</option>
              <option value="广东省">广东省</option>
              <option value="广西壮族自治区">广西壮族自治区</option>
              <option value="海南省">海南省</option>
              <option value="重庆">重庆</option>
              <option value="四川省">四川省</option>
              <option value="贵州省">贵州省</option>
              <option value="云南省">云南省</option>
              <option value="西藏自治区">西藏自治区</option>
              <option value="青海省">青海省</option>
              <option value="宁夏回族自治区">宁夏回族自治区</option>
              <option value="新疆维吾尔自治区">新疆维吾尔自治区</option>
              <option value="台湾">台湾</option>
              <option value="香港特别行政区">香港特别行政区</option>
              <option value="澳门特别行政区">澳门特别行政区</option>
            </select>
            <div class="error" v-if="errorShow && region == ''">省份不能留空</div>
          </div>
        </div>
        <div class="formInput">
          <div class="inputBox" style="width: 100%;margin-bottom: 32px;">
            <div class="inputTitle">
              <span>*</span>
              主营业务和期望合作描述
            </div>
            <textarea class="input input2" placeholder="请填写您的主营业务和期望合作描述..." maxlength="500"
              v-model='zhuyingyewuheqiwangh'></textarea>
            <div class="error" v-if="errorShow && zhuyingyewuheqiwangh == ''">主营业务和期望合作描述不能为空</div>
          </div>
        </div>
        <div class="formBtn" @click="onClick">提交</div>
      </div>
      <div v-if="!userFormBox && codeImgUrl" class="codeBox">
        <img src="./../../public/img/close.png" class="close" @click="formShow">
        <img src="./../../public/img/codeboxImg.png" class="codeboxImg">
        <div class="codeTitle">提交成功！</div>
        <div class="codeText">我们将在1-3个工作日联系您，与您具体沟通合作事宜</div>
        <div class="codeImgBox">
          <img :src='codeImgUrl'>
          <div class="codeImgtext">识别图中二维码加微信</div>
        </div>
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bottom from '../components/bottom.vue'

export default {
  name: 'cooperative',
  components: {
    navlist: nav,
    bottom
  },
  data() {
    return {
      codeImgUrl: null,
      userFormBox: true,
      errorShow: false,
      formState: false,
      user_name: '',
      mobile: '',
      zhuyingyewuheqiwangh: '',
      corp_name: '',
      huobanleixing: '',
      position: '',
      region: ''
    }
  },
  methods: {
    to(str) {
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    },
    formShow() {
      this.formState = !this.formState
      this.userFormBox = true
      this.errorShow = false
      this.user_name = ''
      this.mobile = ''
      this.zhuyingyewuheqiwangh = ''
      this.corp_name = ''
      this.huobanleixing = ''
      this.position = ''
      this.region = ''
    },
    onClick() {
      // eslint-disable-next-line
      if (this.user_name != '' && this.mobile != '' && this.zhuyingyewuheqiwangh != '' && this.corp_name != '' && this.huobanleixing != '' && this.position != '' && this.region != '' && this.mobile.length >= 11) {
        const callback = (data) => {
          this.userFormBox = false
          this.codeImgUrl = data.data.qr_code
        }
        const wlCustomFormComp = window.wlCustomFormComp || {}
        wlCustomFormComp.linkToWL({
          wl_form_id: '1623938733067862016',
          formData: {
            user_name: this.user_name,
            mobile: this.mobile,
            _zhuyingyewuheqiwangh: this.zhuyingyewuheqiwangh,
            corp_name: this.corp_name,
            _huobanleixing: this.huobanleixing,
            position: this.position,
            region: this.region
          }
        }, callback)
      } else {
        this.errorShow = true
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}

</script>
<style scoped lang="scss">
select.input {
  -webkit-transition: none;
  transition: none;
}

.formBoxBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .codeBox {
    position: relative;
    width: 482px;
    height: 445px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);

    .close {
      position: absolute;
      top: 16px;
      z-index: 123;
      right: 16px;
      cursor: pointer;
    }

    .codeboxImg {
      width: 95.73px;
      margin: 42px auto;
    }

    .codeTitle {
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      text-transform: capitalize;
      letter-spacing: 0em;
      color: #333333;
      margin-bottom: 14px;
    }

    .codeText {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: 0em;
      color: #888A8B;
      margin-bottom: 40px;
    }

    .codeImgBox {
      width: 148px;
      height: 159px;
      border-radius: 12px;
      background: #F6F4F4;
      padding-top: 12px;
      margin: 0px auto;

      .codeImgtext {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        letter-spacing: 0em;
        color: #0EC2AD;
      }

      img {
        width: 124px;
        margin-bottom: 12px;
        height: 125px;
      }
    }
  }

  .formBox {
    width: 666px;
    padding: 32px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);
    position: relative;

    .close {
      position: absolute;
      top: 16px;
      z-index: 123;
      right: 16px;
      cursor: pointer;
    }

    .formBtn {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      background: #252B4F;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
      font-family: PingFangSC-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin: 0 auto;
      cursor: pointer;
    }

    .formInput {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .inputBox {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        .inputTitle {
          font-family: PingFangSC-Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          color: #22394E;
          margin-bottom: 12px;
          text-align: left;

          span {
            color: #FF4749;
          }
        }

        .input {
          background: #F6F6F6;
          box-sizing: border-box;
          border: 1px solid #D8D8D8;
          height: 48px;
          border-radius: 4px;
          outline-color: rgba(34, 57, 78, 0.8);
          padding: 16px;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          line-height: 14px;
          letter-spacing: 0em;
          color: rgba(34, 57, 78, 1);
        }

        .input2 {
          height: 110px;
          resize: none;
          line-height: 18px;
        }

        .error {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          line-height: 14px;
          letter-spacing: 0em;
          color: #FF4749;
          text-align: left;
          margin-left: 16px;
          margin-top: 3px;
        }
      }
    }
  }
}

.h5 {
  display: none;
}

.pc {
  display: block;
}

.cooperative {
  width: 100%;

  .topBox {
    width: 100%;
    height: 630px;

    img {
      width: 100%;
      height: 630px;
      object-fit: cover;
    }

    .topTitle {
      width: 890px;
      margin: 0 auto;
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 0;
      align-items: flex-start;
      right: 0;
      top: 230px;

      .titleName {
        font-family: Source Han Sans CN;
        font-size: 66px;
        line-height: 80px;
        letter-spacing: 0.06em;
        color: #FFFFFF;

      }

      .titleName2 {
        font-weight: bold;

      }

      .btn {
        margin-top: 40px;
        width: 138px;
        height: 44px;
        border-radius: 12px;
        background: #D9F9E5;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
        letter-spacing: 0px;
        color: #252B4F;
        cursor: pointer;
      }
    }
  }

  .jobPush {
    font-family: PingFang SC;
    font-size: 33px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 80px
  }

  .jobPushImg {
    width: 905px;
    margin: 0px auto;
    margin-bottom: 100px
  }

  .jobPushBtn {
    width: 238px;
    height: 60px;
    border-radius: 12px;
    background: #252B4F;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    font-family: PingFangSC-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0px;
    color: #D9F9E5;
    margin: 0 auto;
    margin-bottom: 100px;
    cursor: pointer;
  }

  .pagebox2 {
    width: 100%;
    height: 1082px;
    background: linear-gradient(131deg, #EAD9F9 -12%, #D9DDF9 13%, #D9F9E5 128%), #D9F9E5;
    padding-top: 110px;
    margin-bottom: 110px;

    div {
      font-family: PingFang SC;
      font-size: 33px;
      font-weight: 600;
      line-height: 33px;
      text-align: center;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 122px;
    }

    img {
      width: 1082px;
      margin: 0 auto;
    }
  }

  .pagebox {
    width: 100%;

    .pageCenter {
      width: 1106px;
      margin: 0 auto;
      padding-top: 77px;
      padding-bottom: 36px;

      .pageCenterTitle {
        margin: 0 auto;
        width: 940px;
        padding-bottom: 60px;
        font-family: PingFang SC;
        font-size: 33px;
        font-weight: 600;
        line-height: 54px;
        text-align: center;
        letter-spacing: 0px;
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 16px;

        span {
          color: #58bfad;
          font-size: 33px;
          font-weight: 600;
        }
      }

      .pageCenterTitle2 {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 300;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0px;
        color: #666666;
        margin-bottom: 113px;
      }

      .pageCenterBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;

        .titleBox {
          width: 262px;

          .titleName {
            font-family: PingFang SC;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 16px;
            margin-top: 30px;
          }

          .btmText {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #666666;
            text-align: left;
          }

          .btmText2 {
            padding: 0 80px;
            border-left: 1px #DFDFDF solid;
            border-right: 1px #DFDFDF solid;

            span {
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: normal;
              line-height: 18px;
              letter-spacing: 0px;
              color: #2A2A2A;
            }
          }
        }

        .titleBox2 {
          width: 422px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .pc {
    display: none;
  }

  .h5 {
    display: block;
  }

  .h5topBox {
    width: 100%;
    height: 408px;

    img {
      width: 100%;
      height: 408px;
      object-fit: cover;
    }

    .h5topTitle {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 50px;
      align-items: flex-start;
      right: 0;
      top: 180px;

      .h5titleName {
        font-family: PingFang SC;
        font-size: 29px;
        line-height: 29px;
        letter-spacing: 0px;
        color: #FFFFFF;
        font-weight: lighter;
        margin-bottom: 10px;
      }

      .h5titleName2 {
        font-weight: bold;
      }

      .h5btn {
        margin-top: 24px;
        width: 100px;
        height: 30px;
        border-radius: 8px;
        background: #D9F9E5;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0px;
        color: #252B4F;
        cursor: pointer;
      }
    }
  }

  .h5NameTop {
    width: 100%;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0px;
    padding-top: 36px;
    padding-bottom: 22px;
    border-bottom: 1px solid #D9D9D9;

    span {
      color: #58bfad;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .h5NameTopText {
    margin-top: 12px;
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0px;
    color: #666666;
    margin-bottom: 40px;
  }

  .h5BoxTitle {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;

    .h5Box {
      width: 136px;

      img {
        height: 44px;
      }

      .h5BoxName {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.12px;
        text-align: center;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 6px;
        margin-top: 30px;
      }

      .h5BoxText {
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0px;
        color: #666666;
        text-align: left;
      }
    }
  }

  .h5mscp {
    width: 100%;
    padding: 35px 0px;
    background: linear-gradient(163deg, #EAD9F9 -25%, #D9DDF9 5%, #D9F9E5 136%), #D9F9E5;

    div {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 33px;
    }

    img {
      width: 90%;
    }
  }

  .h5jobPush {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 38px;
    margin-bottom: 22px
  }

  .h5jobPushImg {
    width: 100%;
    margin: 0px auto;
    margin-top: 38px;
  }

  .h5jobPushBtn {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    background: #252B4F;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    font-family: PingFangSC-Medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0px;
    color: #D9F9E5;
    margin: 0 auto;
    margin-bottom: 40px;
    cursor: pointer;
  }
}
</style>
