<template>
  <div class="bluehead">
    <bluepictext
      btntype='gre'
      :btn="btn"
      :btn1="btn1"
      :title="title"
      :sub="subtitle"
      :list="list"
      :hide="true"
      :src="src"
      :video="video"
      @btn="$emit('btn', $event)"
      @btn1="$emit('btn1', $event)" />
    <downtip
      :title="down"
      :tip="subdown" />
    <img class="b" src="/img/b.png" />
  </div>
</template>
?
<script>
import bluepictext from './bluepictext.vue'
import downtip from './downtip.vue'
export default {
  name: 'bluehead',
  props: ['btn', 'btn1', 'video', 'title', 'subtitle',
    'list', 'down', 'subdown', 'src'],
  components: {
    bluepictext,
    downtip
  }
}
</script>

<style scoped lang="scss">
.bluehead {
  height: 1308px;
  background: #252B4F;
  // box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5000);
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding-top: 349px;
  .bluepictext {
    width: 1200px;
    /deep/ {
      .bluetextinfo {
        height: 351px !important;
      }
      .title {
        color: #D9F9E5;
        font-size: 45px;
        text-align: left;
        padding: 0;
        line-height: 1.2;
      }
      .sub {
        color: #D9F9E5;
        text-align: left;
      }
      .item {
        &.txt {
          height: 351px;
          margin-right: 80px;
        }
        img {
          border: none;
          width: 676px;
          height: 400px;
          border-radius: 0;
        }
      }
      .button {
        width: 180px;
        height: 50px;
        background: #D9F9E5;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #252B4F;
        line-height: 25px;
        font-weight: bolder;
        i {
          color: #D9F9E5;
        }
      }
    }
  }
  .downtip {
    margin-top: 170px;
    /deep/ {
      .l1 {
        font-size: 22px;
        font-weight: 500;
        color: #D7F0E1;
        line-height: 30px;
      }
      .l2 {
        font-size: 14px;
        font-weight: 500;
        color: #D7F0E1;
        line-height: 20px;
      }
      i {
        color: #D7F0E1;
      }
    }
  }
  .b {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 860px) {
  .bluehead {
    height: auto;
    padding: 0;
    padding-top: 150px;
    .downtip {
      display: none;
    }
    .bluepictext {
      width: 100%;
      height: 660px;
      flex: 1;
      height:  auto !important;
      /deep/ {
        .item {
          >img {
            height: 360px;
            margin-bottom: 0;
          }
          .title {
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D9F9E5;
            line-height: 33px;
            margin-top: 30px;
          }
          .sub {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D9F9E5;
            line-height: 17px;
            margin-top: 20px;
          }
          .list {
            flex: none;
            .item {
              font-size: 10px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #D9F9E5;
              line-height: 12px;
              margin-top: 10px;
            }
          }
          .button {
            margin-top: 50px;
          }
        }
      }
    }
    .b {
      display: none;
    }
  }
}
</style>
