import { render, staticRenderFns } from "./emplo.vue?vue&type=template&id=a37505fe&scoped=true&"
import script from "./emplo.vue?vue&type=script&lang=js&"
export * from "./emplo.vue?vue&type=script&lang=js&"
import style0 from "./emplo.vue?vue&type=style&index=0&id=a37505fe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a37505fe",
  null
  
)

export default component.exports