var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bluepictext",class:{
      verse: _vm.verse,
      ani: _vm.cls !== undefined,
      on: !!(_vm.cls || {}).on
    },style:({
      flexDirection: _vm.verse ? 'row-reverse' : 'row'
    })},[_c('div',{staticClass:"item txt"},[_c('bluetextinfo',{attrs:{"btntype":_vm.btntype,"sub":_vm.sub,"title":_vm.title,"content":_vm.content,"list":_vm.list,"high":_vm.high,"hide":_vm.hide,"btn":_vm.btn,"btn1":_vm.btn1,"type":_vm.type,"head":_vm.head},on:{"btn":function($event){return _vm.$emit('btn')},"btn1":function($event){return _vm.$emit('btn1')}}})],1),_c('div',{staticClass:"item"},[(_vm.src && !(_vm.src instanceof Array))?_c('img',{style:(_vm.btn==='iOS下载'||_vm.btn==='Android下载'||_vm.btn=='iOS'||_vm.btn=='Android'?'border:0px':'border: 20px solid  #CEF0FE;'),attrs:{"src":_vm.src}}):_vm._e(),(_vm.src && (_vm.src instanceof Array))?_c('div',{staticClass:"box"},[_c('div',{staticClass:"boxin",style:({
            transform: `translate(-${(_vm.confi) * (_vm.isphone ? 329 : 620)}px)`
          })},_vm._l((_vm.src),function(i,k){return _c('img',{key:k,staticClass:"boximg",attrs:{"src":i}})}),0)]):_vm._e(),(_vm.video)?_c('div',{staticClass:"videoout"},[_c('video',{ref:"video",staticStyle:{"cursor":"poniter"},style:({
            transform: _vm.scale ? `scale(${_vm.scale})` : '',
            transformOrigin: _vm.tt ? '0 0' : ''
          }),attrs:{"src":_vm.video,"loop":"","muted":"muted","autoplay":"autoplay"},domProps:{"muted":true}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }