<template>
  <div class="ex">
    <navlist :curIndex="1" @login="$emit('visi')" />
    <div class="inner">
      <div class="ii">近期案例</div>
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/qIkR3puXUaZLQ8rFGPXNjw')" src="/img/newExImg.png"
        verse="true" title="百人同屏，沉浸互动|元宇宙让高质量企业培训更简单"
        content="ARK世界精选两场企业培训类元宇宙活动，一起探究头部企业如何借助元宇宙，低成本高效率的完成这一任务。帮助成员迅速了解企业文化，提升关键技能" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/mJS1DtJX8Iy618OqsOTWag')" btn1="了解更多" src="/img/newExImg2.png"
        title="案例解析｜元宇宙为企业盈利的三大方法" content="让元宇宙空间产生盈利，最直接的三大方法。适合快销、会展、培训、互联网产品等领域" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/XUhbG1R6hI1xQzkQoNjuTQ')" src="/img/newExImg3.jpg"
        verse="true" title="ARK世界荣登首届中国最受欢迎元宇宙空间榜单"
        content="深耕中国客户真实需求，开拓“ARK元宇宙商业世界”：为虚拟展会、会议、教育培训、文旅等元宇宙未来商业带来更多价值体验。" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/saVoUVitq8KLEA9P8t6naA')" btn1="了解更多" src="/img/newExImg4.jpg"
        title="ARK元宇宙&上海市数字广告园区达成试点合作，助力企业跨越线上线下的次元壁"
        content="借助平台先进技术，为企业用户提供丰富的元宇宙场景，助力企业实现数字化转型和创新实践。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/Hj4Ckkn0JT31lN-VbH6pjg')" src="/img/newExImg5.jpg"
        verse="true" title="ARK元宇宙商业世界上线：中国版元宇宙正在深耕客户真实需求"
        content="深挖元宇宙应用落地功能，助力企业轻松实现增长" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/2rLtTHT3OlDhgXIMCTQn_w')" btn1="了解更多" src="/img/newExImg6.png"
        title="ARK元宇宙&韩国五魅光州元宇宙城市馆今日首次开放"
        content="ARK元宇宙打造的韩国光州广域市元宇宙城市馆将于2023年4月6日（今日）正式上线。光州元宇宙城市馆的设立旨在展示其城市文化风貌和先进产业，进一步推动经贸乃至文化层面的交流与合作。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/PcMWwybvuCOONiUG_Hst6Q')" src="/img/1exImg.png"
        verse="true" title="ARK世界新地标：演艺中心和展示中心亮相"
        content="随着ARK世界的不断发展，越来越多的建筑和设施陆续上线。近日，ARK 世界又上线了2、3号楼，分别为演艺中心和展示中心。这两座楼的建成，为 ARK 世界的发展迈出了坚实的一步，也将为各类用户提供更好的使用体验，也将为 ARK 世界带来新的文化和创意。" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/vED-lVqam1Qo3kQvKzJSjw')" btn1="了解更多" src="/img/2exImg.png"
        title="中国电信元宇宙学习世界" content="中国电信「破壁计划」通过元宇宙技术，实现了教学环境和个体身份的双重虚拟化，满足线上线下、虚拟现实连贯融合的教与学新需要。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/yRtKeO4gnNtaeLFUVNT5yQ')" src="/img/3exImg.png"
        verse="true" title="创业邦2022数字化发展峰会"
        content="创业邦盛大举办了“2022数字化发展峰会”大会，旨在通过分享众多成功转型的企业巨头、品牌的优秀数字化案例给行业带来重构未来的一些新启发。活动由“ARK元宇宙办公平台”独家提供元宇宙定制服务。" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/eBZfhLisW1JdrVWyM6EDwQ')" btn1="了解更多" src="/img/4exImg.png"
        title="在元宇宙工作的1天：2022年世界数字农业大会"
        content="首创了数字农业元宇宙线上展厅，数字与科技元素爆棚，4大展厅，700多个企业、技术、专家等方面的优秀案例各显风采……参与者足不出户“逛”展厅，一个“永不落幕”的盛会就此诞生。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/Rn9CXaDnEYvNnSJBtMhCEw')" src="/img/5exImg.png"
        verse="true" title="2022全球创业周中国站元宇宙会场"
        content="全球创业周是全球规模最大的创业盛会之一，ARK元宇宙完成“大型会议快速落地极限挑战”——30天内落地全球级别、聚集嘉宾展商超过100+、正式流程在7天左右的超大型会展。" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/ojXSMBy3ctGZ3P5k22YHRA')" btn1="了解更多" src="/img/6exImg.png"
        title="2022全球金融科技精英汇"
        content="2022全球金融科技精英汇暨滨湖金融小镇首届金融科技大赛决赛代表了金融科技领域学术高度，由清华大学金融科技研究院作为学术指导单位，合肥兴泰金融控股（集团）有限公司主办,同步设立首个全国双创周元宇宙会场，集结来自全国的16个优质金融科技路演项目的优秀参赛者。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/1AdY0jaZETrXJZN22NEtLg')" src="/img/8exImg.png"
        verse="true" title="2022粤港澳大湾区集邮展览" content="粤港澳大湾区集邮展览是中华全国集邮联合会为粤港澳大湾区而设的国家级专项性竞赛类邮展，也是我国唯一一个跨境邮展。
邮展设有线上元宇宙展厅及线下展场双重观展渠道，实现线上线下同步办展，创造性地提供了沉浸式体验。活动期间共计吸引超过400万人次进行元宇宙观展体验。" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/L9weEBdYVn_s0QKLKTozQg')" btn1="了解更多" src="/img/9exImg.png"
        title="兰迪律所元宇宙法庭"
        content="ARK元宇宙针对法律行业低容错率的痛点，聚合司法、学术、科技等多方角色共同参与，打造了一个数字孪生的元宇宙法庭进行模拟庭审，让“模拟庭审”如同游戏副本，可以无限次重开，并配有详细资深的角色注意事项介绍，帮助新手们更好地试错、成长。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/qiuLGCmUNVvUTDbrbrcg9w')" src="/img/10exImg.png"
        verse="true" title="中国电信培训专场" content="集团内统一线上培训-快速丨高效
在新技术持续更新迭代、市场环境急剧变迁、企业战略快速演变的大环境下，人才培养需求愈发强烈。人事部门作为企业经营管理发展战略中的关键构成部分，更需要建立健全企业的人才培养机制，紧跟时代潮流开拓新的人才培养形式。" />
      <bluepictext @btn1="to('https://mp.weixin.qq.com/s/aIYdBoSE2qu53W-h-bEXNA')" btn1="了解更多" src="/img/11exImg.png"
        title="2022年在华韩资企业人才招聘会"
        content="本次元宇宙招聘会聚集了50+个优秀在华韩资企业参展，吸引了近千名全球人才通过元宇宙的方式投递简历、面试聊offer。一站式、一天内完成招聘双方的深度沟通，甚至完成多方尝试、对比选择，极大地节约了企业和求职者的时间、人力成本，提高效率。" />
      <!-- <bluepictext btn1="了解更多" @btn1="to('')" src="/img/11exImg.png" verse="true" title="" content="" /> -->

      <bluepictext btn="去体验" @btn1="to('https://mp.weixin.qq.com/s/m-dtJ5K2wP2cjXqYmnwurg')" btn1="了解更多"
        @btn="to('https://www.huadongmeta.com/onlineark/index.html#/invite/hub/66944c9c6ece49b899b6f1263fc77768')"
        src="/img/ex16.png" title="施耐德电气、欧莱雅等企业巨头如何开放式创新？DEMO WORLD世界创新峰会揭秘"
        content="2020年伊始，开放式创新研究者黄震在《开放式创新·中国式创新实践指南》的引言中这样写道：“对消费者的陌生、对技术的疑惑、对未来经济发展的焦虑，共同形成了企业家和企业高管们的恐慌。于是，他们孜孜不倦，上下求索。终于，有两个字如同黑暗中的一道闪电，让他们看到些许希望：创新！”" />
      <bluepictext btn="去体验"
        @btn="to('https://www.huadongmeta.com/onlineark/index.html#/invite/meeting/a0ed8bd03d1e4284a0f888ceec719c7d')"
        btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/EzxFEegPfZ2tDMLUUGFpOg')" src="/img/ex3.jpeg" verse="true"
        title="ARK元宇宙X 2022创业邦3040新青年创投峰会成功举办：30位国内优秀创业者和40位顶尖投资人组局，云上闭门会畅聊未来"
        content="2022年6月8日“行者皆勇者”——3040新青年创投峰会是由国内领先的专业创业服务平台创业邦主办，聚集国内70位优秀顶尖的青年创投人参与。" />
      <bluepictext btn1="了解更多" @btn1="to('https://mp.weixin.qq.com/s/MZRd-WfAvRhXVpdyEvSciA')" btn="去体验"
        @btn="to('https://www.huadongmeta.com/onlineark/index.html#/invite/meeting/6d04b2c594224a9f91ef2edab6fb34b0')"
        src="/img/ex2.png" title="2022年华东理工艺设计与传媒学院本科生毕业典礼"
        content="2022年6月23日14：00，由ARK元宇宙办公平台独家承办的《2022年华东理工艺设设计与传媒学院本科生毕业典礼》，在ARK团队为华理艺设学子们精心打造的元宇宙空间内，盛大举行。" />
      <bluepictext btn="了解更多" @btn="to('https://mp.weixin.qq.com/s/sF1zPBme-cxorZGOS_GccA')" src="/img/ex17.png"
        verse="true" title="助力光州广域市数字化进程，ARK承办元宇宙宣传馆"
        content="2022年7月起，大韩民国光州广域市政府将与ARK元宇宙服务平台展开一系列深度合作。
根据拟定的《2022-2023年光州广域市元宇宙宣传馆运营方案》，在主场馆光州广域市元宇宙宣传馆的规划设计上，ARK元宇宙服务平台计划在第一期合作中实现光幕开幕式、photo zone、视频播映、media art pop-up展览墙、showroom等细分板块功能。" />
      <bluepictext btn="了解更多" @btn="to('https://mp.weixin.qq.com/s/zzvOwjomYTmW6CeC8xhNfg')" src="/img/ex18.png"
        title="会展主办必看！0风险办展、低成本、强传播！" content="2022年对会展行业来说，是前所未有的寒冬之年，无数大大小小的车展、漫展、行业峰会在各种因素综合影响下被迫取消或延期。
不少会展行业从业者将目光投向“远程会展”，但传统远程工具多多少少会损失会展的氛围感，限制了大型活动的展商规模、 人流规模和互动体验，实际效果一般。" />
      <bluepictext btn="了解更多" @btn="to('https://mp.weixin.qq.com/s/sK4QVBehHn803TfGGV3zAg')" src="/img/ex8.gif"
        verse="true" title="TRTC X ARK | 疫情时代, 新社交互动趋势下全球远程办公的破圈"
        content="突如其来的疫情让远程办公协同需求骤然爆发，而在后疫情时代，随着疫情由一个变量成为一种常态，大量线上办公协同软件进入了我们的日常工作流程。" />
      <bluepictext btn="了解更多" @btn="to('https://mp.weixin.qq.com/s/H_Wf9PsG7-mvquJYA_vxJA')" src="/img/ex1.jpeg"
        title="开营预告|同济仕院生拍了拍你说：7月4日“元宇宙”相见！"
        content="疫情暂时阻隔了我们的物理空间，但无法阻隔我们的热情与执着。此次暑期训练营我们将首次将依托ARK平台开启元宇宙办公新模式，仕院生和导师可通过元宇宙空间进行办公交流、小组讨论、讲座分享、营员答辩等内容。" />
      <bluepictext btn="了解更多"
        @btn="to('https://mp.weixin.qq.com/s?__biz=Mzg5MTg1NzI3Mw==&mid=2247494731&idx=1&sn=f776f5452cd865b50b6f8085f7dedf64&source=41#wechat_redirect')"
        src="/img/ex4.jpeg" verse="true" title="元宇宙办公室起风了"
        content="渴望虚拟办公，未必是因为“时髦”，而是像零态这种新媒体企业长期以来的痛点。仅仅立足北京，很难构建出如今一般风格多样、奇思妙想的编辑团队。秉持着为公众带来最棒文章的初心，零态作者和编辑团队来自五湖四海，这意味着实体的办公空间并不能够满足我们的工作需求。" />
      <bluepictext btn="了解更多" @btn="to('https://36kr.com/p/1752229697634308?channel=moments')" src="/img/ex5.png"
        title="最前线 | 元宇宙办公平台「ARK」全新发布2.0版本，提升企业核心竞争力"
        content="ARK元宇宙平台是由上海与你信息科技有限公司打造的国内首家元宇宙办公平台。ARK元宇宙招聘产品在市场获得积极反响后，结合公司在疫情期间的内部远程办公实践，发现目前市场主流的线上办公软件对于企业核心及现实问题" />
      <bluepictext btn="了解更多"
        @btn="to('https://www.bilibili.com/video/BV1yY4y1577s?vd_source=149f1d2e512ceea347aae9458b679def')"
        src="/img/ex6.webp" verse="true" title="意外闯进国内首个元宇宙办公室 会议 协同办公 体验竟然不错 WEWORK 超vrchat 虹宇宙"
        content="元宇宙不远了吗？国内首个“元宇宙上班”公司来了：你想象过在元宇宙世界进行打卡、开会、演讲吗！今天带你体验" />
      <bluepictext btn="了解更多" @btn="to('https://mp.weixin.qq.com/s/zT9K2iAJA0J2l1UCSq3ccg')" src="/img/ex9.png"
        title="NFT数字藏品平台——“链物空间”的首次大型公测元宇宙活动"
        content="2022年4月15日晚上6点迎来了，与国家级区块链BSN-DDC基础设施的合规的数字藏品平台——“链物空间”的首次大型公测活动。这次元宇宙公测活动是萌芽期ARK迄今承接的最大的一场活动。" />
      <!-- <bluepictext btn="了解更多"
              @btn="to('https://www.bilibili.com/video/BV1wa41117RL?vd_source=149f1d2e512ceea347aae9458b679def')"
              src="/img/ex12.webp" verse="true" title="用元宇宙平衡通勤和房租，我做到了！" content="花动科技从今年1月开始实行元宇宙办公制之后，我的生活竟然发生了这样的变化？
快来看看元宇宙办公的日常是什么样的吧！" />
          <bluepictext btn="了解更多"   @btn="to('https://mp.weixin.qq.com/s/6p3Ej1uF2WXskbS3j7XETQ')" src="/img/ex13.png"
              title="管理者如何提高远程办公效率？用ARK！"
              content="近日花动科技元宇宙项目组发布消息称：「公司将于 2022 年 2 月 1 日开始正式开启元宇宙时代，让公司 200 名员工完全进入 ARK 元宇宙办公」，同时于今年年中「撤去公司总部大楼，将目前花动科技 2000 平方米的上海七宝办公室改造成线下团队聚会空间。" />
          <bluepictext btn="了解更多"   @btn="to('https://mp.weixin.qq.com/s/BFX5MR64GwulLuLuhl47sg')" src="/img/ex14.png" verse="true"
              title="元宇宙派对？乘坐元宇宙去新世代"
              content="近期的元宇宙现象，我们被塞入了很多场景、空间、概念。但我们不禁会问这个被描述为未来“进化版”的世界，会让我们的生活更好吗？我带着好奇及疑问走访这家公司。目前花动已累计服务1亿用户，实现1万亿量级数据存储和30亿元的商业收入。" />
          <bluepictext btn="了解更多"   @btn="to('https://36kr.com/p/1607566088145664')" src="/img/ex15.jpeg"
              title="“在虚拟世界中实现全员Avatar办公，「花动科技」推出“ARK元宇宙办公平台"
              content="总部位于上海的「花动科技」同样看到了元宇宙办公的机会，自去年12月便开始进行“元宇宙”系列实践，并于2022年2月正式推出了自主开放“ARK元宇宙办公平台”。" />
          <bluepictext btn="了解更多"   @btn="to('https://mp.weixin.qq.com/s/9pg5xhTbZMR0ffXAB5DFJg')" src="/img/ex11.png" verse="true"
              title="KIC中国创业大赛线下线上元宇宙路演，在华韩国团队、青年企业家、VC代表参加会议"
              content="2022年3月25日下午2点，2022KIC中国创业大赛（以下简称“创业大赛”）在北京皇冠假日酒店、及首次在ARK元宇宙平台线上线下同步举行。" /> -->
    </div>
    <div class="btmBtmBox">
      <span class="companyName">{{ companyName }}</span> 网站名称： <span class="webName">{{ webName }}</span> <a
        href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #D3D7E2;"
        class="recordNumber">{{ recordNumber }}</a><br>
      公司地址：<span>上海市闵行区 宜山路2000号利丰广场2号楼2楼115室 </span> 电话：021-60443097&nbsp;&nbsp;手机号：13122524646<br>
      <div style="width:300px;margin:0 auto;" class="record"> <a target="_blank" :href='recordUrl'
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><!--img
                      src="https://www.cashtoutiao.com/index/images/police.png" lazysrc=""
                      style="float:left;width: 20px;" /-->
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#D3D7E2;">沪公网安备
            {{ record }}号</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bluepictext from '../components/bluepictext.vue'

export default {
  name: 'ex',
  components: {
    navlist: nav,
    bluepictext
  },
  data() {
    return {
      list: [],
      recordNumber: null,
      companyName: null,
      webName: null,
      record: null,
      recordUrl: null
    }
  },
  mounted() {
    if (location.origin.indexOf('huadongmeta') >= 0) {
      this.recordNumber = '沪ICP备2022000367号－1'
      this.companyName = '上海与你信息科技有限公司'
      this.webName = 'ARK元宇宙办公协作平台'
      this.record = '31011202014100'
      this.recordUrl = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014100'
    } else {
      this.recordNumber = '沪ICP备2022019997号－1'
      this.companyName = '上海舟渊信息科技有限公司'
      this.webName = 'ark元宇宙官方网站'
      this.record = '31011202014407'
      this.recordUrl = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014407'
    }
  },
  methods: {
    to(str) {
      window.open(str)
    }
  }
}
</script>

<style scoped lang="scss">
.btmBtmBox {
  width: 100%;
  background: #000;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 28px;
  text-align: center;
  position: absolute;
  bottom: 0;

  span {
    color: #fff;
  }

  div {
    color: #fff;
  }

}

.ex {
  width: 100%;
  background: rgba(37, 43, 79, 1);
  padding-top: 294px;
  padding-bottom: 200px;
  position: relative;

  .inner {
    width: 1200px;
    margin: 0 auto;
  }

  .ii {
    font-size: 28px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    text-align: left;
    margin-bottom: 15px;
  }

  .bluepictext {
    background: #D9F9E5;
    border-radius: 12px;
    width: 1200px;
    box-sizing: border-box;
    padding: 30px 80px;
    margin-bottom: 50px;

    /deep/ {
      .item {
        &:not(.txt) {
          flex: none;
          height: auto;
        }

        >img {
          width: 525px;
          height: 295px;
          border-radius: 5px;
          border: none;
        }

        &.txt {
          .bluetextinfo {
            height: 320px !important;
          }

          .title {
            // flex: 1;
            flex: none;
            width: 100%;
            text-align: left;
          }

          .content {
            flex: 1;
          }
        }
      }

      .button {
        color: rgba(217, 249, 229, 1);

        i {
          color: rgba(217, 249, 229, 1);
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .ex {
    padding-top: 122px;
    padding-bottom: 0px;

    .ii {
      display: none;
    }

    .inner {
      width: 100%;
    }

    .bluepictext {
      margin: 0 18px 25px 18px;
      width: calc(100% - 36px);
      height: auto;
      box-sizing: border-box;
      padding: 13px 15px;
      box-sizing: border-box;
      align-items: center;

      /deep/ {
        .item {
          width: 100%;

          >img {
            width: 100%;
            height: 172px;
            border-radius: 5px;
            border: none !important;
          }

          .button {
            margin: 10px 0 0 0;
          }

          &.txt {
            .bluetextinfo {
              // height: 320px !important;
              height: 100% !important;
              align-items: flex-end;

              .btnBox {
                margin: 0 auto !important;
              }
            }

            .high {
              font-size: 16px;
              line-height: 20px;
            }

            .title {
              // flex: 1;
              flex: none;
              font-size: 16px;
              line-height: 20px;
              padding: 0;
            }

            .content {
              flex: 1;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .btmBtmBox {
    width: 100%;
    background: #000;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 28px;
    text-align: center;
    position: inherit;
    height: 260px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;

    span {
      color: #fff;
    }

    div {
      color: #fff;
    }

  }
}</style>
