<template>
  <div class="bluepictext"
    :class="{
      verse,
      ani: cls !== undefined,
      on: !!(cls || {}).on
    }"
    :style="{
      flexDirection: verse ? 'row-reverse' : 'row'
    }">
    <div class="item txt">
      <bluetextinfo
        :btntype="btntype"
        :sub="sub"
        :title="title"
        :content="content"
        :list="list"
        :high="high"
        :hide="hide"
        :btn="btn"
        :btn1="btn1"
        :type="type"
        :head="head"
        @btn="$emit('btn')"
        @btn1="$emit('btn1')"/>
    </div>
    <div class="item">
      <img :src="src" v-if="src && !(src instanceof Array)" :style="btn==='iOS下载'||btn==='Android下载'||btn=='iOS'||btn=='Android'?'border:0px':'border: 20px solid  #CEF0FE;'" />
      <div class="box" v-if="src && (src instanceof Array)">
        <div class="boxin"
          :style="{
            transform: `translate(-${(confi) * (isphone ? 329 : 620)}px)`
          }">
          <img class="boximg" :src="i" v-for="(i, k) in src"
            :key="k"/>
        </div>
      </div>
      <div class="videoout"  v-if="video">
        <video :src="video" loop
          muted="muted"
          autoplay="autoplay"
          style="cursor:poniter"
          ref="video"
          :style="{
            transform: scale ? `scale(${scale})` : '',
            transformOrigin: tt ? '0 0' : ''
          }"/>
      </div>
    </div>
  </div>
</template>

<script>
import bluetextinfo from './bluetextinfo.vue'
export default {
  name: 'bluepictext',
  props: ['head', 'tt', 'btntype', 'video', 'scale', 'type', 'src', 'cls', 'title', 'btn', 'btn1', 'sub', 'content', 'verse', 'high', 'list', 'hide'],
  components: {
    bluetextinfo
  },
  data () {
    return {
      confi: 0,
      isphone: false
    }
  },
  mounted () {
    // window.addEventListener('click', () => {
    //   if (this.$refs.video) {
    //     this.$refs.video.play()
    //     console.log(this.$refs.video)
    //   }
    // })
    if (window.innerWidth < 600) {
      this.isphone = true
    }
    if (this.src && this.src instanceof Array) {
      setInterval(v => {
        if (this.confi < this.src.length - 1) {
          this.confi = this.confi + 1
        } else {
          this.confi = 0
        }
      }, 3000)
    }
  },
  methods: {
    play () {
      if (this.$refs.video.paused) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bluepictext {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1040px;
  margin: 0 auto;
  overflow: hidden;
  &.ani {
    &.verse {
      .item {
        // transition: all 1s ease;
        &:first-child {
          .bluetextinfo {
            // display: none;
            /deep/ {
              .high {
                transform: translate(150%, 0);
                opacity: 0;
                transition: all 1s ease 0.3s;
              }
              .title {
                transform: translate(150%, 0);
                opacity: 0;
                transition: all 1s ease 0.3s;
              }
              .sub {
                transform: translate(150%, 0);
                opacity: 0;
                transition: all 1s ease 0.3s;
              }
              .content {
                transform: translate(0, 100%);
                opacity: 0;
                transition: all 1s ease 0.8s;
              }
              .button {
                transform: translate(0, 100%);
                opacity: 0;
                transition: transform,opacity 1s ease 1s;
              }
            }
          }
        }
        // &:last-child {
        //   transform: translate(-100%, 0);
        //   transition: all 1s ease 1s;
        // }
      }
    }
    .item {
      // transition: all 1s ease;
      // opacity: 0;
      &:first-child {
        // transform: translate(-100%, 0);
        // transition: all 1s ease;
        .bluetextinfo {
            // display: none;
          /deep/ {
            .high {
              transform: translate(-150%, 0);
              opacity: 0;
                transition: all 1s ease 0.3s;
            }
            .title {
              transform: translate(-150%, 0);
              opacity: 0;
                transition: all 1s ease 0.3s;
            }
            .sub {
              transform: translate(-150%, 0);
              opacity: 0;
                transition: all 1s ease 0.3s;
            }
            .content {
              transform: translate(0, 100%);
              opacity: 0;
                transition: all 1s ease 0.8s;
            }
            .button {
              transform: translate(0, 100%);
              opacity: 0;
                transition: transform,opacity 1s ease 1s;
            }
          }
        }
      }
      // &:last-child {
      //   transform: translate(100%, 0);
      //   transition: all 1s ease 1s;
      // }
    }
    &.on {
      .item {
        &:first-child {
          .bluetextinfo {
            /deep/ {
              .high {
                transform: translate(0, 0);
                opacity: 1;
              }
              .title {
                transform: translate(0, 0);
                opacity: 1;
              }
              .sub {
                transform: translate(0, 0);
                opacity: 1;
              }
              .content {
                transform: translate(0, 0);
                opacity: 1;
              }
              .button {
                transform: translate(0, 0);
                opacity: 1;
              }
            }
          }
        }
        &:last-child {
          transform: translate(0, 0);
        }
      }
    }
  }
  &.verse {
    .item {
      &:last-child {
        margin-right: 105px;
      }
      &:first-child {
        margin-right: 0;
      }
    }
  }
  .item {
    display: block;
    height: 100%;
    &.txt {
      flex: 1;
    }
    &:first-child {
      margin-right: 113px;
    }
    video {
      cursor: pointer;
      transform: scale(calc(580 / 325 / (1920 / 1080)), 1);
      height: 100%;
      transform-origin: 50% 50%;
    }
    .videoout,
    img {
      display: block;
      height: 100%;
      width: 580px;
      height: 325px;
      border: 20px solid  #CEF0FE;
      border-radius: 10px;
    }
    .bluetextinfo {
      flex: 1;
      height: 100%;
    }
  }
  .box {
    width: 620px;
    height: 365px;
    overflow: hidden;
    .boxin {
      width: calc(620px * 6);
      height: 365px;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
@media screen and (max-width: 860px) {
  .bluepictext {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    &.verse {
      flex-direction: column-reverse !important;
      .item {
        margin-right: 0 !important;
      }
    }
    .item {
      margin-right: 0 !important;
      video {
        cursor: pointer;
        transform: scale(calc(313 / 176 / (1920 / 1080)), 1);
        height: 100%;
        transform-origin: 50% 50%;
      }
      .videoout {
        margin-right: 0;
        margin-bottom: 13px;
      }
      .videoout,
      img {
        width: 313px;
        height: 176px;
        border-width: 8px;
        margin-right: 0;
        margin-bottom: 13px;
      }
      .box {
        width: 329px;
        height: 192px;
        overflow: hidden;
        margin-bottom: 13px;
        .boxin {
          width: calc(329px * 6);
          height: 192px;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .bluetextinfo {
    width: 100%;
    align-items: center;
    /deep/ .content {
      margin-top: 10px;
      flex: none;
      height: auto;
    }
    /deep/ .button {
      margin-top: 20px;
    }
  }
}
</style>
