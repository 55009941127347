<template>
  <div class="login">
    <div class="l" >
      <img src="/img/ark.png" />
      <div class="t1">欢迎登录 ARK</div>
      <div class="l1">手机号/E-mail 登录体验</div>
      <div class="input">
        <input placeholder="请输入/E-mail 或手机号"
          v-model="name"
          @input="checkname"/>
        <div class="tip">{{time ? `${time}s` : ''}}</div>
        <div class="tip ab"
          @click="send">{{time ? '已发送' : sended ? '重新发送' : '发送验证码'}}</div>
      </div>
      <div class="l2 m">{{nameerr}}</div>
      <div class="input">
        <input placeholder="请输入验证码"
          v-model="code"
          @input="checkcode"/>
      </div>
      <div class="l2">{{codeerr}}</div>
      <div class="ll">
        <el-checkbox
          v-model="check">
        </el-checkbox>&nbsp;
        我已阅读并同
        《<a href="https://www.huadongmeta.com/arkAgreement/service.html"
        target="_blank">
        服务协议
        </a>》和
        《<a href="https://www.huadongmeta.com/arkAgreement/privacy.html"
          target="_blank">
        隐私协议 </a>》
      </div>
      <div class="btn anibtn ani">登录</div>
    </div>
    <div class="r" >
      <video src="/video/right.mp4"
        autoplay="true"
        loop
        muted="muted"
        :style="{
          height: !widthmorelogin ? '100%' : 'auto',
          width: !widthmorelogin ? 'auto' : '100%',
        }" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'login',
  components: {
  },
  data () {
    return {
      nameerr: ' ',
      codeerr: ' ',
      name: '',
      code: '',
      time: '',
      sended: false,
      check: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    checkname () {
      if (/^1[0-9]{10}$/.test(this.name) || /\S*@\S*\.\S*/.test(this.name)) {
        this.nameerr = ' '
        this.sended = false
      } else {
        this.nameerr = '请输入正确的手机号/E-mail'
      }
      this.$forceUpdate()
    },
    checkcode () {
      if (/\S{4}/.test(this.code)) {
        this.codeerr = ' '
      } else {
        this.codeerr = '输入异常'
      }
      this.$forceUpdate()
    },
    send () {
      if (this.time) {
        return null
      } else {
        this.sended = true
        this.time = 60
        this.inter = setInterval(() => {
          if (this.time <= 0) {
            clearInterval(this.inter)
            this.time = ''
          } else {
            this.time = this.time - 1
          }
        }, 1000)
      }
    }
  },
  computed: {
    ...mapState(['widthmorelogin'])
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .l {
    width: 640px;
    background: #252B4F;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > img {
      width: 135px;
      height: 78px;
      margin-bottom: 140px;
    }
    .t1 {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 29px;
      width: 360px;
      text-align: center;
      margin-bottom: 32px;
    }
    .l1 {
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 20px;
      width: 360px;
      text-align: left;
      margin-bottom: 10px;
    }
    .input {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      input {
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 20px;
        height: 30px;
        border: 0;
        padding: 0 5px;
        background: transparent;
        outline: none;
        flex: 1;
      }
      width: 360px;
      text-align: left;
      border-bottom: 0.5px solid #FFFFFF;
      box-sizing: border-box;
      position: relative;
      .tip {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D9F9E5;
        line-height: 17px;
        &.ab {
          position: absolute;
          right: -70px;
          cursor: pointer;
        }
      }
    }
    .l2 {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #CEF0FE;
      line-height: 17px;
      width: 360px;
      text-align: left;
      margin-top: 5px;
      min-height: 17px;
    }
    .ll {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      a {
        margin: 0;
        color: #fff;
      }
    }
    .m {
      margin-bottom: 32px;
    }
    .btn {
      width: 90px;
      height: 40px;
      background: #D9F9E5;
      border-radius: 8px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #252B4F;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      cursor: pointer;
    }
  }
  .r {
    flex: 1;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    img {
      height: 100%;
    }
  }
}

@media screen and (max-width: 860px) {
  .login{
    .l {
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      padding: 0 43px;
      > img {
        width: 85px;
        height: 47px;
      }
      .t1 {
        width: 100%;
        display: none;
      }
      .l1 {
        width: 100%;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D9F9E5;
        line-height: 17px;
      }
      .input {
        width: 100%;
      }
      .l2 {
        width: 100%;
      }
      .tip,
      .tip.ab {
        position: relative !important;
        right: unset !important;
      }
    }
    .r {
      display: none;
    }
  }
}
</style>
