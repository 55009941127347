<template>
  <div class="downtip">
    <div class="l1">
        {{title}}
    </div>
    <div class="l2">
        {{tip}}
    </div>
    <i class="el-icon-arrow-down" />
  </div>
</template>

<script>
export default {
  name: 'downtip',
  props: ['title', 'tip']
}
</script>

<style scoped lang="scss">
.downtip {
    .l1 {
        font-size: 22px;
        font-weight: 500;
        color: #252B4F;
        line-height: 30px;
    }
    .l2 {
        font-size: 14px;
        font-weight: 500;
        color: #252B4F;
        line-height: 20px;
        margin-top: 8px;
    }
    i {
        font-size: 25px;
        font-weight: 800;
        color: rgba(37, 43, 79, 1);
        margin-top: 23px;
    }
}

@media screen and (max-width: 860px) {
  .downtip {
    .l1 {
      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #233057;
      line-height: 14px;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .l2 {
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #233057;
      padding:0 10px;
      // line-height: 11px;
      // white-space: nowrap;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    i {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
</style>
