<template>
    <div class="officialAccountBox">
      <div class="rightBox">
        <div class="consult consultLine" @click.stop="openWX">
          <img src="./../../public/img/weixinzx.png" alt="">
          <div>微信咨询</div>
        </div>
        <div class="consult" @click.stop="openOfficialAccount">
          <img src="./../../public/img/weixingzh.png" alt="">
          <div>微信公众号</div>
        </div>
      </div>
      <div class="officialAccount" v-show="orCode"><div>关注微信公众号</div><img src="./../../public/img/code.jpeg" alt=""></div>
      <div class="officialAccount" v-show="wxShow" style="height: 265px;bottom: 150px;"><div>微信咨询</div>
      <div class="title">添加客服微信，了解更多详情</div>
        <div id="wl-qrcode"></div>
        <div id="wxIdUrl"></div>
      </div>
      <!-- <wlQrcode v-show="wxShow" @openWX="openWX" @openSingTwo="openSingTwo"/> -->
    </div>
</template>

<script>
// import wlQrcode from '../components/wlQrcode.vue'

export default {
  name: 'officialAccountBox',
  components: {
    // wlQrcode
  },
  data () {
    return {
      orCode: false,
      wxShow: false
    }
  },
  methods: {
    os () {
      var ua = navigator.userAgent
      var isWindowsPhone = /(?:Windows Phone)/.test(ua)
      var isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
      var isAndroid = /(?:Android)/.test(ua)
      var isFireFox = /(?:Firefox)/.test(ua)
      // var isChrome = /(?:Chrome|CriOS)/.test(ua)
      var isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
      var isPhone = /(?:iPhone)/.test(ua) && !isTablet
      var isPc = !isPhone && !isAndroid && !isSymbian
      if (isAndroid || isPhone) {
        return false
      } else if (isTablet) {
        return false
      } else if (isPc) {
        return true
      }
    },
    openWX () {
      this.$track.webClick('微信咨询')
      if (this.os()) {
        this.wxShow = !this.wxShow
        this.orCode = false
      } else {
        window.open('https://customer.huadongmeta.com/one-click-jump-page?wl_id=wpW2WmDAAAbkL5rxjEhSTf2TO0dL_q6A&wl_btn_id=1641321908718923776', '_self')
      }
    },
    openSingTwo () {
      this.orCode = true
    },
    openOfficialAccount () {
      this.$track.webClick('微信公众号')
      this.orCode = !this.orCode
      this.wxShow = false
    }
  }
}
</script>

<style scoped lang="scss">

.rightBox{
    width: 72px;
    height: 148.91px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 3.46px 12.1px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border: 0.6px solid #FFFFFF;
    backdrop-filter: blur(8.18px);
    position: fixed;
    right: 10px;
    bottom: 72px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .consultLine{
      border-bottom: 1px solid #D4D4D4;
    }

    .consult{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      height: 50%;
      img{
        width: 35px;
        height: 35px;
      }
      div{
        font-family: 思源黑体;
        font-size: 11.45px;
        font-weight: normal;
        line-height: 11.45px;
        letter-spacing: 0em;
        color: #22394E;
      }
    }
}
.officialAccount{
  width: 224px;
  height: 245px;
  border-radius: 5.87px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3.46px 12.1px 0px rgba(0, 0, 0, 0.15);
  border: 0.73px solid #FFFFFF;
  position: fixed;
  z-index: 1;
  bottom: 72px;
  right: 85px;
  div{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #22394E;
    margin-top: 18px;
    margin-bottom: 11px;
  }

  .title{
    font-family: 苹方-简;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0em;
    color: #888A8B;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  img{
    width: 181px;
    height: 181px;
  }

  #wl-qrcode{
    width: 181px;
    height: 181px;
    margin: auto;
  }

  #wl-qrcode /deep/ img{
    width: 181px !important;
    height: 181px !important;
    padding: 0 !important;
  }

}

    @media screen and (max-width: 860px) {
      .rightBox{
        bottom: 100px;
      }
    }
</style>
