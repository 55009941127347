<template>
  <div class="emplo">
    <navlist
      @login="$emit('visi')"/>
    <bluehead
      title="ARK元宇宙招聘"
      subtitle="人才 无界"
      :list="[
        'ARK是一种超越地理限制的未来生活方式',
        '10-在ARK的招聘空间，你可以和全球人才见面、沟通'
      ]"
      src="/img/employ1.jpg"
      btn="和藤校高材生聊聊"
      down="随时，随地，随心，互通，互联"
      subdown="元宇宙营销展会，元宇宙沉浸式办公，元宇宙企业招聘，元宇宙校院宣讲会"/>
    <bluepictext
      src="/img/employ2.jpg"
      verse="true"
      title="全球人才，尽在元宇宙"
      content="再也不用坐飞机、坐火车来总公司面试了
      应聘者和招聘方双向节约成本
      只需进入ARK，就可直接沟通，超级高效"
      btn="建立招聘"/>
    <bluepictext
      src="/img/employ1.jpg"
      title="丰富的角色表情动作，展示自我"
      content="通过表情/动作等动态互动方式展示自己的心情与个性
通过创新型场景，带来沉浸式的互动体验，迅速拉近距离
真实互动交流的温度，让沟通自然地发生在工作与生活的每一处"
      btn="体验互动"/>
    <bluepictext
      src="/img/employ2.jpg"
      verse="true"
      title="想给人才更好的初印象？"
      content="在ARK，你可以定制属于团队的专属办公室
1:1数字孪生复刻线下办公室？可以
天马行空地想把办公室搬到外太空？没问题"
      btn="创建场景"/>
    <bluepictext
      src="/img/employ1.jpg"
      title="多线进行，互不打扰"
      content="ark成熟的招聘流程方法论，应聘者只聚焦于你
      预定时间，面试官和候选人自动瞬移到面试空间
      保证面试流程的可控性和准确度"
      btn="面试空间"/>
    <div class="out">
      <div class="w1040 infolist">
        <bluepictext
          src="/img/employ2.jpg"
          title="招聘也可以不线下见面进行吗？"
          content="对没错，作为未来的生活方式，
          元宇宙招聘有线下绝不可替代的优势
          跨越距离，交流无价 当你需要全球的优秀人才
          当你需要精准规范的招聘流程
          者当你需要一个私密的谈薪空间
          ARK始终对你敞开怀抱"
          btn="开始设计！"/>
        <contactinfo />
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bluehead from '../components/bluehead.vue'
import bluepictext from '../components/bluepictext.vue'
import bottom from '../components/bottom.vue'
import contactinfo from '../components/contactinfo.vue'
// import infocard from '../components/infocard.vue'

export default {
  name: 'emplo',
  components: {
    navlist: nav,
    bluehead,
    bluepictext,
    contactinfo,
    // infocard,
    bottom
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="scss">
.emplo {
  background: #F6F6F6;
  > .bluepictext {
    margin-bottom: 100px;
    &:nth-child(3) {
      margin-top: 80px;
    }
  }
  .out {
    background: #D9F9E5;
  }
  .w1040 {
    margin: 0 auto;
    width: 1040px;
    padding-top: 80px;
    overflow: hidden;
  }
  .infolist {
    /deep/ {
      .bluepictext {
        margin-bottom: 60px;
      }
      .txt {
        margin-right: 80px;
        .bluetextinfo {
          height: 360px !important;
        }
      }
    }
    .tt {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #26305F;
      line-height: 70px;
      -webkit-text-stroke: 1px #26305F;
      text-stroke: 1px #26305F;
      text-align: left;
    }
    .list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 80px;
      .infocard:not(:last-child) {
        margin-right: 32px;
      }
      .infocard {
      }
    }
  }
}
</style>
