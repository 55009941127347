<template>
  <div class="conf">
      <!-- @btn="to('index')" -->
      <!-- @btn="to('login')" -->
    <navlist
      @login="$emit('visi')"/>
    <bluehead
      title="ARK元宇宙会展"
      subtitle="现场感＋仪式感＋交流感＋沉浸感"
      :list="[
        '沉浸式3D展会场景定制',
        '10-1000人的丰富模板选择',
        '元宇宙一键邀请，在家参会'
      ]"
      :video="(!isphone || true) ? '/video/0首页-元宇宙会议.mp4' : ''"
      :src="(isphone && false) ? '/gif/0首页-元宇宙会议.GIF' : ''"
      btn="快来定制你的元宇宙会议"
      @btn="to()"
      down="随时，随地，随心，互通，互联"
      subdown="元宇宙营销展会，元宇宙沉浸式办公，元宇宙企业招聘，元宇宙校院宣讲会"/>
    <bluepictext
      :cls="{on: list.indexOf('p1') > -1}"
      :scale="isphone ? `${(313 / 176 / (1802 / 1080))}, 1` : `${(580 / 325 / (1802 / 1080))}, 1`"
      :video="(!isphone || true) ? '/video/1产品页-会议-鼓掌舞蹈2.mp4' : ''"
      :src="(isphone && false) ? '/gif/1产品页-会议-鼓掌舞蹈2.GIF' : ''"
      verse="true"
      :high="['鼓掌舞蹈云蹦迪，',
    '自由互动']"
      type="highlist"
      content="高拟真定制元宇宙活动场景
        容纳人数无上限
        在云端来一场热闹非凡的聚会吧"
      hide="true"
      btn="举办会议"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p2') > -1}"
      high="一键邀人"
      :scale="isphone ? `${(313 / 176 / (1797 / 1080))}, 1` : `${(580 / 325 / (1797 / 1080))}, 1`"
      :video="(!isphone || true) ? '/video/2产品页-会议-一键邀人.mp4' : ''"
      :src="(isphone && false) ? '/gif/2产品页-会议-一键邀人.GIF' : ''"
      type="highhead"
      title="躺着参会
      相隔万里，如处一室"
      content="在家，在咖啡厅，在度假沙滩上
        随时邀请好友进入大型分享会
        打破时间地域限制，超越传统会议的非凡体验"
      hide="true"
      btn="主持会议"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p3') > -1}"
      :video="(!isphone || true) ? '/video/3产品页-会议-投屏.mp4' : ''"
      :src="(isphone && false) ? '/gif/3产品页-会议-投屏.GIF' : ''"
      verse="true"
      high="屏幕投屏 站上舞台"
      content="全场收音 开始你的表演
        观众在会场任意位置都可以看到演讲台c位主持"
      hide="true"
      btn="组织聚会"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p4') > -1}"
      :scale="isphone ? `${(313 / 176 / (2115 / 1080))}, 1` : `${(580 / 325 / (2115 / 1080))}, 1`"
      :video="(!isphone || true) ? '/video/4会议管理.mp4' : ''"
      tt="true"
      :src="(isphone && false) ? '/gif/4会议管理.GIF' : ''"
      type="lineend"
      head="会议管理"
      high="数据统计"
      title="实时字幕 自动翻译"
      content="和全球潮流年轻人们一起嗨皮，担心语言问题？
      ARK提供实时字幕翻译，保证沟通顺畅无碍"
      hide="true"
      btn="管理会议"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p5') > -1}"
      :src="[
        '/img/会议场景1.png',
        '/img/会议场景2.png',
        '/img/会议场景3.png',
        '/img/会议场景4.png',
        '/img/会议场景5.png',
        '/img/会议场景6.png'
      ]"
      verse="true"
      high="海量会议场景模版，"
      title="自定义装修，身临其境"
      content="针对不同的会议形式，选择不同元宇宙场景
      自定义配置会场装修，沉浸式体验企业氛围
      提供分会场管理系统，保证顶级沉浸式互动"
      hide="true"
      btn="装修会场"
      @btn="to()"/>
    <div class="out">
      <div class="w1040 infolist">
        <div class="tt">
          如何开始元宇宙会议之旅？
        </div>
        <div class="list">
          <infocard
            :cls="{on: list.indexOf('p6') > -1}"
            src="/img/conf1.jpg"
            :height="isphone ? 340 : 415"
            title="1. 选择场景"
            :content="['ARK提供了相当多的场景模板，选择一个最符合心意的。',
              '多少人？什么风格？要开放的还是私密的？ 总有一款适合你。']"/>
          <infocard
      :cls="{on: list.indexOf('p6') > -1}"
            src="/img/conf2.jpg"
            :height="isphone ? 340 : 415"
            title="2. 定制细节"
            :content="['椅子放哪里？这盆花放哪里？要不要在墙上加个海报？',
            '每一个细节都是可以调整的。请多多尝试！']"/>
          <infocard
      :cls="{on: list.indexOf('p6') > -1}"
            src="/img/conf3.jpg"
            :height="isphone ? 340 : 415"
            title="3. 邀请宾客"
            :content="['一个链接，轻松搞定邀请步骤。',
            '让我们现在就出发！燥起来！']"/>
        </div>
        <contactinfo />
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bluehead from '../components/bluehead.vue'
import bluepictext from '../components/bluepictext.vue'
import bottom from '../components/bottom.vue'
import contactinfo from '../components/contactinfo.vue'
import infocard from '../components/infocard.vue'
import { mapState } from 'vuex'

export default {
  name: 'conf',
  components: {
    navlist: nav,
    bluehead,
    bluepictext,
    contactinfo,
    infocard,
    bottom
  },
  data () {
    return {
      list: [],
      meetingUrl: null
    }
  },
  computed: {
    ...mapState(['isphone'])
  },
  mounted () {
    var dataNum
    if (location.search === '') {
      dataNum = '?'
    } else {
      dataNum = location.search
    }
    this.meetingUrl = 'https://www.huadongmeta.com/onlineark/index.html#/entrance/meeting' + dataNum
    window.scrollTo(0, 0)
    window.addEventListener('scroll', (e) => {
      // console.log(window.pageYOffset + window.innerHeight)
      if (window.innerWidth < 600) {
        if (window.pageYOffset + window.innerHeight > 830 && this.list.indexOf('p1') === -1) {
          this.list.push('p1')
        }
        if (window.pageYOffset + window.innerHeight > 1280 && this.list.indexOf('p2') === -1) {
          this.list.push('p2')
        }
        if (window.pageYOffset + window.innerHeight > 1700 && this.list.indexOf('p3') === -1) {
          this.list.push('p3')
        }
        if (window.pageYOffset + window.innerHeight > 2070 && this.list.indexOf('p4') === -1) {
          this.list.push('p4')
        }
        if (window.pageYOffset + window.innerHeight > 2500 && this.list.indexOf('p5') === -1) {
          this.list.push('p5')
        }
        if (window.pageYOffset + window.innerHeight > 3200 && this.list.indexOf('p6') === -1) {
          this.list.push('p6')
        }
      }
      if (window.pageYOffset + window.innerHeight > 1470 && this.list.indexOf('p1') === -1) {
        this.list.push('p1')
      }
      if (window.pageYOffset + window.innerHeight > 1960 && this.list.indexOf('p2') === -1) {
        this.list.push('p2')
      }
      if (window.pageYOffset + window.innerHeight > 2400 && this.list.indexOf('p3') === -1) {
        this.list.push('p3')
      }
      if (window.pageYOffset + window.innerHeight > 2880 && this.list.indexOf('p4') === -1) {
        this.list.push('p4')
      }
      if (window.pageYOffset + window.innerHeight > 3360 && this.list.indexOf('p5') === -1) {
        this.list.push('p5')
      }
      if (window.pageYOffset + window.innerHeight > 3980 && this.list.indexOf('p6') === -1) {
        this.list.push('p6')
      }
    })
  },
  methods: {
    to () {
      // window.scrollTo(0, 0)
      // this.$router.push({
      //   path: './' + str
      // })
      window.open(this.meetingUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.conf {
  background: #F6F6F6;
  > .bluepictext {
    margin-bottom: 100px;
    &:nth-child(3) {
      margin-top: 80px;
    }
  }
  .out {
    background: #D9F9E5;
  }
  .w1040 {
    margin: 0 auto;
    width: 1040px;
    padding-top: 80px;
    overflow: hidden;
  }
  .infolist {
    .tt {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #26305F;
      line-height: 70px;
      -webkit-text-stroke: 1px #26305F;
      text-stroke: 1px #26305F;
      text-align: left;
    }
    .list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 80px;
      .infocard:not(:last-child) {
        margin-right: 32px;
      }
      .infocard {
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .conf {
    .bluepictext {
      margin: 40px 0 !important;
      /deep/ {
        .bluetextinfo {
          height: auto !important;
        }
      }
    }
    .w1040 {
      width: 100%;
      .tt {
        text-align: center;
      }
      .list {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>
