<template>
  <div class="infocard"
    :class="{
      ani: cls !== undefined,
      on: !!(cls || {}).on
    }"
    :style="{
      height: height + 'px'
    }">
    <img class="img" :src="src" />
    <div class="content">
      <div class="t">
        {{title}}
      </div>
      <template v-if="isarr">
        <div class="c"
          v-for="(item, k) in content"
          :key="k">
          {{item}}
        </div>
      </template>
      <template v-else>
        <div class="c">
          {{content}}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'infocard',
  props: ['src', 'title', 'content', 'height', 'cls'],
  computed: {
    isarr () {
      return this.content instanceof Array
    }
  }
}
</script>

<style scoped lang="scss">
.infocard {
  width: 325px;
  height: 446px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.ani {
    opacity: 0;
    transform: translate(100%, 0);
    transition: all 1s ease;
    &:nth-child(1) {
    }
    &:nth-child(2) {
      transition: all 1s ease 0.5s;
    }
    &:nth-child(3) {
      transition: all 1s ease 1s;
    }
    &.on {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  .img {
    width: 325px;
    height: 223px;
  }
  .content {
    width: 100%;
    flex: 1;
    .t {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      line-height: 55px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .c {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
      text-align: left;
      padding: 0 15px;
      box-sizing: border-box;
      white-space: pre-line;
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 860px) {
  .infocard {
    width: 100vw;
    margin-bottom: 30px;
    .img {
      width: 100%;
      height: calc(100vw / 375 * 211);
    }
    .content {
      .t {
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222C4D;
        line-height: 55px;
      }
      .c {
        text-align: center;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222C4D;
        line-height: 18px;
      }
    }
  }
}
</style>
