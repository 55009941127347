<template>
  <div class="exhibition">
    <navlist
      @login="$emit('visi')"/>
    <div v-if="visi" class="mask"
      @click="visi = false">
      <i class="el-icon-close" />
      <video controls
        autoplay
        src="/video/APK元宇宙-尾部信息(压缩).mp4"
        @click.stop/>
      <!-- <video controls
        autoplay
        v-else
        src="/video/首页首屏背景混剪手机.mp4"
        @click.stop /> -->
    </div>
    <div class="video">
      <img class="bg" src="/img/zhanhuitop.png" />
      <!-- <video class="bg" src="/video/首屏混剪.mp4"
        v-if="!isphone"
        muted="muted"
        autoplay="autoplay"
        :style="{
          transformOrigin: '0 0',
          transform: `scale(${fulltrans})`,
          height: widthmore ? '100%' : 'auto',
          width: widthmore ? 'auto' : '100%',
        }"
        loop
        ref="video"/>
      <video class="bg" src="/video/首页首屏背景混剪手机.mp4"
        :style="{
          height: !widthmore ? '100%' : 'auto',
          width: !widthmore ? 'auto' : '100%',
        }"
        muted="muted"
        autoplay="autoplay"
        loop
        v-else /> -->
      <div class="con">
        <div class="t1">开启元宇宙展会新时代</div>
        <!-- <div class="t2"></div> -->
        <div class="headlist">
          <div>模拟线下团队办公场景，强调团队凝聚力</div>
          <div>远程监督考勤管理，解决远程管理问题</div>
          <div>碎片化会议沟通，音视频随时沟通</div>
        </div>
        <div class="play"
          @click="play" style="opacity: 0;">
          <i class="el-icon-caret-right" />
        </div>
        <bbutton
          type="gre"
          hide="true"
          txt="领取专属定制"
          @click="open('self')"></bbutton>
        <!-- <div class="m">
          <bbutton
            type="gre"
            hide="true"
            txt="快来你的办公室看看"
            @click="visipop = true"></bbutton>
        </div> -->
      </div>
    </div>
    <!-- <downtip title="随时，随地，随心，互通，互联"
      tip="元宇宙营销展会，元宇宙沉浸式办公，元宇宙企业招聘，元宇宙校院宣讲会" /> -->
    <bluepictext
      :cls="{on: list.indexOf('p1') > -1}"
      src='/img/zhanhuiImg.png'
      high="01 科技博览会"
      title=""
      content="丰富的个性化展台空间，自定义添加产品相关社交功能及数字孪生内容，打造属于自己的虚拟展位，在虚拟展位中客充分展示公司科技产品和前沿技术，参会者可以体验沉浸式逛展，穿梭于展厅和多种论坛现场。"
      :hide="true"
      />
    <bluepictext
      :cls="{on: list.indexOf('p2') > -1}"
      verse="true"
      high="02 创投峰会"
      title=""
      content="线下活动及线上元宇宙同步，打破常规线上形式，开启沉浸式交流，提升线上活动的效率，更低的活动组织运营成本，更高的话题性，无论在参与意愿和分享欲方面，都有独到的优势。"
      src='/img/zhanhuiImg2.png'
      :hide="true"
      />
    <bluepictext
      :cls="{on: list.indexOf('p3') > -1}"
      src='/img/zhanhuiImg3.png'
      high="03 发布会"
      title=""
      content="3D虚拟空间，根据品牌需求多场景便捷式切换，实时快速直播，不同虚拟场景搭配不同主题，超高自由度diy，极大提升宣传效率。"
      :hide="true"
      />
    <div class="centerBox">
      <div class="experience">
        <img src="./../../public/img/zhanhuicenter.png" alt="">
        <div class="experienceTitle">DEMO WORLD世界创新峰会</div>
        <div class="experienceText">云上闭门会，畅谈未来<br/> 创业邦元宇宙峰会  更未来的互动交流方式</div>
        <div class="experienceBtn" @click="openUrl('https://www.huadongmeta.com/onlineark/index.html#/invite/hub/66944c9c6ece49b899b6f1263fc77768')">立即体验</div>
      </div>
      <!-- <div class="experience">
        <img src="./../../public/img/zhanhuicenter2.png" alt="">
        <div class="experienceTitle">粤港澳大湾区集邮展</div>
        <div class="experienceText">线上线下邮展同步展出<br/>元宇宙科技与集邮文化融合</div>
        <div class="experienceBtn">暂未开放</div>
      </div> -->
      <div class="experience">
        <img src="./../../public/img/zhanhuicenter3.png" alt="">
        <div class="experienceTitle">大型国际科技博览会</div>
        <div class="experienceText">元宇宙路演大厅，虚实交融超越传统的全新体验<br/>全方位动态展示，沉浸式漫游</div>
        <div class="experienceBtn" @click="openUrl('https://www.huadongmeta.com/onlineark/index.html#/invite/hub/5262489771f04d45a1093e3a571fe071')">立即体验</div>
      </div>
    </div>
    <div class="out">
      <div class="w1040">
        <!-- <div class="t">
          近期案例
        </div>
        <div class="li">
          <infocard
            :cls="{on: list.indexOf('p4') > -1}"
            src="/img/创业邦.jpg"
            :height="isphone ? 320 : undefined"
            title="2022创业邦3040新青年创投峰会"
            content="潮流玩具NFT社区平台元宇宙发布会
万人同时在线蹦迪，氛围感拉满" />
          <infocard
            :cls="{on: list.indexOf('p4') > -1}"
            src="/img/诺亚.png"
            :height="isphone ? 320 : undefined"
            title="Beyond EXPO 2022"
            content="规模盛大的超越国际科技创新博览会，围绕生命科学、可持续发展、消费科技等三大领域，吸引全球超20000名科技创新追求者及500+海内外科技企业参展。" />
          <infocard
            :cls="{on: list.indexOf('p4') > -1}"
            src="/img/kic.jpg"
            :height="isphone ? 320 : undefined"
            title="2022KIC元宇宙创业大赛"
            content="才华横溢的年轻人们分享精彩创意
线上线下同步并行，仪式感十足" />
        </div> -->
        <div class="t">
          如何开始元宇宙展会之旅？
        </div>
        <div class="li ppp">
          <infocard
            :cls="{on: list.indexOf('p5') > -1}"
            src="/img/zhanhuibtm.png"
            title="定制专属展会"
            :height="isphone ? 330 : undefined"
            content="根据业务需求，专业团队提供展会样例参考" />
          <infocard
            :cls="{on: list.indexOf('p5') > -1}"
            src="/img/zhanhuibtm2.png"
            title="个性化诉求"
            :height="isphone ? 330 : undefined"
            content="根据自我需求增添音视频、社交等数字孪生内容" />
          <infocard
            :cls="{on: list.indexOf('p5') > -1}"
            src="/img/zhanhuibtm3.png"
            title="打破圈层"
            :height="isphone ? 330 : undefined"
            content="差异化体验形式，给客户来带全新的感受，增加附加价值" />
        </div>
        <!-- <div class="logos">
          <img
            :class="{on: list.indexOf('p6') > -1}"
            src="/img/logo6.png" />
          <img
            :class="{on: list.indexOf('p6') > -1}"
            src="/img/logo5.png" />
          <img
            :class="{on: list.indexOf('p6') > -1}"
            src="/img/logo7.png" />
        </div>
        <div class="logos">
          <img
            :class="{on: list.indexOf('p7') > -1}"
            src="/img/logo3.png" />
          <img
            :class="{on: list.indexOf('p7') > -1}"
            src="/img/logo1.png" />
          <img
            :class="{on: list.indexOf('p7') > -1}"
            src="/img/logo2.png" />
          <img
            :class="{on: list.indexOf('p7') > -1}"
            src="/img/logo4.png" />
        </div>
        <div class="comps">
          <div class="tt">
            助力企业实现数字化转型
          </div>
          <div class="lis">
            <div class="com">
              <img src="/img/i1.png" />
              <div class="it">50000<span>+</span></div>
              <div class="des">元宇宙居民数</div>
            </div>
            <div class="com">
              <img src="/img/i2.png" />
              <div class="it">3000<span>+</span></div>
              <div class="des">虚拟空间数</div>
            </div>
            <div class="com">
              <img src="/img/i3.png" />
              <div class="it">2000<span>+</span></div>
              <div class="des">入驻企业合作方</div>
            </div>
          </div>
        </div> -->
        <!-- <contactinfo /> -->
      </div>
    </div>
    <bottom style="height: auto;" />
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bluepictext from '../components/bluepictext.vue'
// import contactinfo from '../components/contactinfo.vue'
import infocard from '../components/infocard.vue'
import bottom from '../components/bottom.vue'
import bbutton from '../components/button.vue'
// import downtip from '../components/downtip.vue'
import { mapState } from 'vuex'
export default {
  name: 'exhibition',
  components: {
    navlist: nav,
    bluepictext,
    infocard,
    bottom,
    bbutton
    // ,
    // contactinfo,
    // downtip
  },
  data () {
    return {
      visi: false,
      visipop: false,
      offi: 0,
      confi: 0,
      list: []
    }
  },
  computed: {
    ...mapState(['isphone', 'widthmore', 'fulltrans'])
  },
  mounted () {
    // window.addEventListener('click', () => {
    //   this.$refs.video.play()
    // })
    window.scrollTo(0, 0)
    window.addEventListener('scroll', (e) => {
      // console.log(window.pageYOffset + window.innerHeight)
      if (window.innerWidth < 600) {
        if (window.pageYOffset + window.innerHeight > 830 && this.list.indexOf('p1') === -1) {
          this.list.push('p1')
        }
        if (window.pageYOffset + window.innerHeight > 1370 && this.list.indexOf('p2') === -1) {
          this.list.push('p2')
        }
        if (window.pageYOffset + window.innerHeight > 1830 && this.list.indexOf('p3') === -1) {
          this.list.push('p3')
        }
        if (window.pageYOffset + window.innerHeight > 2060 && this.list.indexOf('p4') === -1) {
          this.list.push('p4')
        }
        if (window.pageYOffset + window.innerHeight > 3360 && this.list.indexOf('p5') === -1) {
          this.list.push('p5')
        }
        if (window.pageYOffset + window.innerHeight > 4800 && this.list.indexOf('p6') === -1) {
          this.list.push('p6')
        }
        if (window.pageYOffset + window.innerHeight > 4900 && this.list.indexOf('p7') === -1) {
          this.list.push('p7')
        }
        return
      }
      if (window.pageYOffset + window.innerHeight > 700 && this.list.indexOf('p1') === -1) {
        this.list.push('p1')
      }
      if (window.pageYOffset + window.innerHeight > 1400 && this.list.indexOf('p2') === -1) {
        this.list.push('p2')
      }
      if (window.pageYOffset + window.innerHeight > 2100 && this.list.indexOf('p3') === -1) {
        this.list.push('p3')
      }
      if (window.pageYOffset + window.innerHeight > 2100 && this.list.indexOf('p4') === -1) {
        this.list.push('p4')
      }
      if (window.pageYOffset + window.innerHeight > 2100 && this.list.indexOf('p5') === -1) {
        this.list.push('p5')
      }
      if (window.pageYOffset + window.innerHeight > 2400 && this.list.indexOf('p6') === -1) {
        this.list.push('p6')
      }
      if (window.pageYOffset + window.innerHeight > 2500 && this.list.indexOf('p7') === -1) {
        this.list.push('p7')
      }
    })
  },
  methods: {
    openUrl (item) {
      window.open(item)
    },
    open (str) {
      // eslint-disable-next-line no-undef
      if (typeof sensors !== 'undefined' && sensors !== null) {
        // eslint-disable-next-line no-undef
        sensors.track('h5_html', {
          // eslint-disable-next-line quote-props
          'htmlname': str
        })
      }
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    },
    play () {
      this.visi = true
    },
    to (str) {
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exhibition {
  position: relative;
  width: 100%;
  .mask {
    // width: 100%;
    // height: 100%;
    &:nth-child(2) {
      z-index: 999;
    }
    position: fixed;
    background: rgba(0,0,0,0.5);
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    video {
      width: 1200px;
      height: 733px;
      background: #000000;
      position: absolute;
      z-index: 10001;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    i {
      color: #fff;
      position: absolute;
      z-index: 2;
      left: calc(50% + 600px - 20px);
      top: calc(50% - 366px - 20px);
      font-size: 20px;
      cursor: pointer;
      &.blue {
        color: rgba(38, 48, 95, 1);
        left: calc(50% + 600px - 30px);
        top: calc(50% - 232px + 10px);
      }
    }
  }
  .contentbox {
    width: 1200px;
    height: 465px;
    background: #D9F9E5;
    // border: 5px solid #252B4F;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .ti {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #233057;
      line-height: 37px;
    }
    .listli {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 3px;
        .slideout {
          width: 316px;
          height: 178px;
          overflow: hidden;
          .slidein {
            width: calc(316px * 5);
            height: 178px;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
        .points {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            >div {
              width: 7px;
              height: 7px;
              border-radius: 7px;
              background-color: #222C4D;
              margin: 0 3px;
              cursor: pointer;
              &.on {
                background: #fff;
              }
            }
          }
        img {
          display: block;
          width: 316px;
          height: 178px;
          border-radius: 3px;
        }
        .btn {
          width: 130px;
          height: 46px;
          background: #252B4F;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #D9F9E5;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          margin-top: 29px;
          cursor: pointer;
          &.dis {
            background: transparent;
            border: 2px solid #252B4F;
            cursor: no-drop;
            color: #252B4F;
          }
        }
        &:not(:last-child) {
          margin-right: 46px;
        }
      }
    }
  }
  .video {
    width: 100%;
    height: 100vh;
    background: #ccc;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.5);
    }
    .button {
      font-weight: bolder !important;
    }
    .bg {
        display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
    }
    .con {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .t1 {
        font-size: 80px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 95px;
        text-shadow: 0px 1px 3px rgba(0,0,0,0.5000);
      }
      .t2 {
          font-size: 28px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40px;
          letter-spacing: 1px;
          text-shadow: 0px 1px 3px rgba(0,0,0,0.5000);
          margin-top: 6px;
      }
      .play {
        margin-top: 81px;
        width: 70px;
        height: 70px;
        background: rgba(255,255,255,0.3000);
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 64px;
        cursor: pointer;
        i {
          font-size: 50px;
          color: #fff;
        }
      }
    }
  }
  .downtip {
    margin-top: 106px;
  }
  .bluepictext {
    margin-top: 100px;
    &:last-child {
      margin-bottom: 100px;
    }
  }
  .out {
    background: #D9F9E5;
    margin-top: 60px;
  }
  .w1040 {
    margin: 0 auto;
    width: 1040px;
    overflow: hidden;
    padding-top: 80px;
    .t {
      font-size: 28px;
      font-weight: 500;
      color: #26305F;
      line-height: 70px;
      -webkit-text-stroke: 1px #26305F;
      text-stroke: 1px #26305F;
      width: 100%;
      text-align: left;
    }
    .li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      // margin-bottom: 80px;
      .infocard:not(:last-child) {
        margin-right: 32px;
      }
    }
    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 50px;
      .on {
          opacity: 1;
          transform: translate(0, 0);
      }
      img {
        display: block;
        width: 136px;
        height: 136px;
        border: 1px solid #646464;
        border-radius: 136px;
        margin: 0 23px;
        overflow: hidden;
        transform: translate(100%, 0);
        opacity: 0;
        transition: all 0.3s ease;
        &:nth-child(2) {
          transition: all 0.3s ease 0.3s;
        }
        &:nth-child(3) {
          transition: all 0.2s ease 0.6s;
        }
        &:nth-child(4) {
          transition: all 0.2s ease 0.9s;
        }
      }
    }
    .comps {
      margin-top: 82px;
      margin-bottom: 58px;
      .tt {
        text-align: left;
        font-size: 28px;
        font-weight: 500;
        color: #252B4F;
        line-height: 70px;
        -webkit-text-stroke: 1px #26305F;
        text-stroke: 1px #26305F;
        margin-bottom: 24px;
      }
      .lis {
        display: flex;
        align-items: center;
        justify-content: center;
        .com {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 196px;
          width: 329px;
          &:not(:last-child) {
            border-right: 4px solid rgba(34, 44, 77, 1);
          }
          img {
            display: block;
            width: 132px;
            height: 76px;
          }
          .it {
            font-size: 40px;
            color: #222C4D;
            line-height: 40px;
            font-weight: bold;
            font-style: italic;
            span {
              font-size: 60px;
            }
          }
          .des {
            font-size: 24px;
            color: #222C4D;
            line-height: 40px;
          }
        }
      }
    }
  }
  .headlist {
    display: none;
  }
  .ppp {
    .infocard {
      /deep/ {
        overflow: hidden;
        > img {
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .index {
    .mask {
      i {
        right: 10px;
        left: unset;
        display: block;
        width: 20px;
        top: 10px;
        z-index: 10002;
        color: #fff;
      }
      video {
        width: 100vw;
        height: 100vh;
      }
    }
    .downtip {
      margin-top: 40px;
    }
    .w1040 {
      width: 100%;
      .t {
        text-align: center;
      }
      .li {
        flex-direction: column;
        width: 100%;
      }
    }
    // display: none;
    .bluepictext {
      margin-top: 40px;
    }
    .video {
      background: rgba(37, 43, 79, 1);
      // height: 669px;
      .bg {
        height: 669px;
        position: relative;
      }
      .con {
        // position: relative;
        // z-index: 1;
        // top: 0;
        // left: 0;
        // transform: none;
        .t1 {
          font-size: 26px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 37px;
          text-shadow: 0px 0px 1px rgba(0,0,0,0.5000);
        }
        .t2 {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 17px;
          text-shadow: 0px 0px 1px rgba(0,0,0,0.5000);
        }
        .headlist {
          display: none !important;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #D9F9E5;
          line-height: 12px;
          display: block;
          margin: 0 auto;
          margin-top: 20px;
          margin-bottom: 50px;
          div {
            color: #D9F9E5;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 10px;
            &:before {
              content: '';
              display: block;
              width: 7px;
              height: 7px;
              border-radius: 7px;
              background: #D9F9E5;
              margin-right: 5px;
            }
          }
        }
        .play {
          // display: none;
          width: 48px;
          height: 48px;
          border-width: 1px;
          i {
            font-size: 30px;
          }
        }
        // .button {
        //   display: none;
        // }
        .m {
          .button {
            display: flex;
          }
        }
      }
    }
    .w1040 {
    .comps {
      // display: none;
      .tt {
        text-align: center;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #26305F;
        line-height: 23px;
        -webkit-text-stroke: 1px #26305F;
        text-stroke: 1px #26305F;
      }
      .lis {
          .com {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 88px;
            width: 165px;
            &:not(:last-child) {
              border-right: 2px solid rgba(34, 44, 77, 1);
            }
            img {
              display: block;
              width: 88px;
              height: 38px;
            }
            .it {
              font-size: 20px;
              color: #222C4D;
              line-height: 20px;
              font-weight: bold;
              font-style: italic;
              span {
                font-size: 30px;
              }
            }
            .des {
              font-size: 12px;
              color: #222C4D;
              line-height: 20px;
            }
          }
        }
    }
    }
    .w1040 {
      .logos {
        img {
          width: 80px;
          height: 80px;
          border: 1px solid #646464;
          border-radius: 100px;
          margin: 0 5px;
        }
      }
    }
    .contentbox {
      width: 100vw;
      height: 100vh;
      background: #252B4F;
      border-radius: 0;
      box-sizing: border-box;
      padding-top: 70px;
      // padding-top: 130px;
      i {
        top: 10px !important;
        right: 10px;
        width: 20px;
        color: #fff !important;
        left: unset !important;
      }
      .ti {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
      }
      .listli {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .item {
          margin: 0 !important;
          position: relative;
          margin-bottom: 20px !important;
          .points {
            display: none;
          }
          .slideout {
            width: 240px;
            height: 135px;
            background: rgba(0,0,0,0.6000);
            .slidein {
              height: 135px;
              img {
                width: 240px;
                height: 135px;
              }
            }
          }
          .btn {
            // display: none;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.6000);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 22px;
            border-radius: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.centerBox{
  width: 1040px;
  margin: 0 auto;
  margin-top: 120px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  .experience{
    width: 500px;
    img{
      width: 500px;
      height: 300px;
    }

    .experienceTitle{
      font-family: PingFangSC-Semibold;
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #252B4F;
      margin-top: 30px;
      margin-left: 20px;
      margin-bottom: 10px;
    }
    .experienceText{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: justify; /* 浏览器可能不支持 */
      letter-spacing: 0px;
      margin-left: 20px;
      color: #252B4F;
      margin-bottom: 30px;
    }
    .experienceBtn{
      width: 99px;
      height: 34px;
      border-radius: 5px;
      background: #252B4F;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0px;
      text-align: center;
      color: #D9F9E5;
      margin-left: 20px;
      margin-bottom: 70px;
      cursor: pointer;
    }
  }
}
</style>
