import sensors from "./sensors"
import umeng from "./umeng"
import * as config from "../config"

const curTracking = 'umeng'

const getTrackTool = (info) => {
    return (info && info.tool) ? info.tool : curTracking
}

const webClick = (name, extraInfo) => {
    const tool = getTrackTool(extraInfo)
    if (tool === 'umeng') {
        umeng.webClick(name)
    } else {
        sensors.webClick(name)
    }
}

const reportEvent = (evetName, config, extraInfo) => {
    const tool = getTrackTool(extraInfo)
    if (tool === 'umeng') {
        umeng.reportEvent(evetName, config, extraInfo)
    } else {
        sensors.reportEvent(evetName, config)
    }
}

const CONFIG = {
    MEETING: {
        value: 1,
        name: '会议'
    },
    BIGHALL: {
        value: 2,
        name: '展览'
    },
    HUB: {
        value: 3,
        name: '综合园区'
    },
    OFFICE: {
        value: 4,
        name: '办公'
    },
    FLEXSPACE: {
        value: 5,
        name: '自由空间'
    },
}

const SCENE_2_TRACK_TYPE = {
    [config.MEETING]: CONFIG.MEETING,
    [config.BIGHALL]: CONFIG.BIGHALL,
    [config.SPACE]: CONFIG.HUB,
    [config.OFFICE]: CONFIG.OFFICE,
    [config.EXHIBITION]: CONFIG.FLEXSPACE,
}

export default {
    webClick,
    reportEvent,
    CONFIG,
    SCENE_2_TRACK_TYPE
}