<template>
  <div class="bluetextinfo">
    <template v-if="type === 'lineend'">
      <div class="title inline">{{head}}<div class="high inline end" v-if="high">{{high}}</div></div>
      <div class="title">{{title}}</div>
    </template>
    <template v-if="type === 'highhead'">
      <div class="title inline"><div class="high inline head" v-if="high">{{high}}</div>{{title}}</div>
    </template>
    <template v-if="type === 'highlist'">
      <div class="high m" v-for="(item, k) in high"
        :key="k">
        {{item}}
      </div>
    </template>
    <template v-if="!type">
      <div class="high" v-if="high">{{high}}</div>
      <div class="title" v-if="title">{{title}}</div>
    </template>
    <div class="sub" v-if='sub'>{{sub}}</div>
    <div class="content"
      v-if="content"
      >{{content}}
    </div>
    <div class="list" v-if="list">
      <div class="item"
        v-for="(i, k) in list"
        :key="k">
        {{i}}
      </div>
    </div>
    <div class="btnBox">
      <bbtn v-if="btn" :txt="btn"
      :hide="hide"
      :type="btntype"
      @click="$emit('btn')" />
    <bbtn v-if="btn1" :txt="btn1"
      :hide="hide"
      :type="btntype"
      @click="$emit('btn1')" />
    </div>
  </div>
</template>

<script>
import button from './button.vue'
export default {
  name: 'bluetextinfo',
  props: ['head', 'btn', 'btn1', 'btntype', 'title', 'content', 'high', 'sub', 'list', 'hide', 'type'],
  components: {
    bbtn: button
  }
}
</script>

<style scoped lang="scss">
.bluetextinfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 240px !important;
  justify-content: space-between;
  .inline {
    display: inline !important;
    .high.head {
      margin-right: 5px;
    }
    .high.end {
      margin-left: 5px;
    }
  }
  .title,
  .high {
    font-size: 22px;
    font-weight: 600;
    color: #26305F;
    line-height: 35px;
    // -webkit-text-stroke: 1px #26305F;
    // text-stroke: 1px #26305F;
    white-space: pre-line;
    text-align: left;
    padding: 0 10px;
    &.m {
      margin-bottom: 2px;
    }
  }
  .sub {
    font-size: 25px;
    font-weight: 500;
    color: #26305F;
    line-height: 36px;
    white-space: pre-line;
  }
  .high {
    background: #CEF0FE;
  }
  .content {
    flex: 1;
    font-size: 14px;
    color: #222C4D;
    line-height: 25px;
    margin-top: 15px;
    white-space: pre-line;
    text-align: left;
    padding: 0 10px;
  }
  .list {
    margin-top: 36px;
    flex: 1;
    .item {
      font-size: 18px;
      font-weight: 500;
      color: #D9F9E5;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background: #D9F9E5;
        border-radius: 7px;
        margin-right: 7px;
      }
    }
  }
  .button {
    margin:  0 10px;
    color: #d9f9e5;
  }

  .btnBox{
    display: flex;
  }
}

@media screen and (max-width: 860px) {
  .btnBox{
    display: flex;
    flex-direction: column;
    .button{
      margin-top:10px !important
    }
  }

  .sub{
    text-align: center !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
</style>
