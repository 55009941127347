/**
 * 公用配置
 */
//系统类型
export const OFFICE = "office"
export const OFFICE_TYPE = 1
export const MEETING = "meeting"
export const MEETING_TYPE = 2
export const RECRUIT = "recruit"
export const SPACE = "space"
export const EXHIBITION = "exhibitionHall" // 自由模式
export const EXHIBITION_TYPE = 6
export const BIGHALL = "bigHall" // 展会
export const BIGHALL_TYPE = 7
export const sensorsSceneConfig = {
    [OFFICE]: 1,
    [MEETING]: 2,
    [RECRUIT]: 3,
    [SPACE]: 5,
    [EXHIBITION]: 6,
    [BIGHALL]: 7
}

export const ROUTER_2_SCENE = {
    hub: SPACE,
    office: OFFICE,
    meeting: MEETING,
    interview: RECRUIT,
    exhibition: BIGHALL,
    flexspace: EXHIBITION
}

export const SCENE_2_ROUTER = {
    [SPACE]: "hub",
    [OFFICE]: "office",
    [MEETING]: "meeting",
    [RECRUIT]: "interview",
    [BIGHALL]: "exhibition",
    [EXHIBITION]: "flexspace"
}