import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isphone: false,
    widthmore: true,
    widthmorelogin: true,
    fulltrans: '1, 1'
  },
  mutations: {
    setisphone (state, payload) {
      state.isphone = payload
    },
    setwidthmore (state, payload) {
      state.widthmore = payload
    },
    setwidthmorelogin (state, payload) {
      state.widthmorelogin = payload
    },
    setfulltrans (state, payload) {
      state.fulltrans = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
