<template>
  <div class="index">
    <navlist :curIndex="0" @login="$emit('visi')" />
    <div v-if="visi" class="mask" @click="visi = false">
      <i class="el-icon-close" />
      <video controls autoplay src="/video/APK元宇宙-尾部信息(压缩).mp4" @click.stop />
    </div>
    <div class="video">
      <video class="bg" src="/video/官网视频修改版.mov" v-if="!isphone" muted="muted" autoplay="autoplay" :style="{
        transformOrigin: '0 0',
        transform: `scale(${fulltrans})`,
        height: widthmore ? '100%' : 'auto',
        width: widthmore ? 'auto' : '100%',
      }" loop ref="video" />
      <video class="bg" src="/video/官网视频修改版.mov" :style="{
        height: !widthmore ? '100%' : 'auto',
        width: !widthmore ? 'auto' : '100%',
      }" muted="muted" autoplay="autoplay" loop v-else />
      <div class="con">
        <div class="t1">低成本开启元宇宙</div>
        <div class="t2">快速、好用、省钱，定义属于您的元宇宙世界</div>
        <div class="topBtnBox">
          <div class="buttonBox" @click="jump(0)">免费体验</div>
          <div class="play" @click="play">
            <i class="el-icon-caret-right" />
          </div>
        </div>
      </div>
    </div>
    <div class="rotationMap" v-if="dataList.length > 0">
      <swiper class="swiperBox swiper-wrapper" :options="swiperOption()" ref="mySwiper">
        <swiper-slide class="swiperList swiper-slide" v-for="(item, index) in dataList" :key="index">
          <img :src="item.pic" alt="">
          <div class="swiperListBox" v-if="swiperIndex == index" @click="jump(index,item.title)">
            <div class="swiperListBoxTop">{{ item.title }}</div>
            <div class="swiperListBoxBottom">{{ item.describe }}</div>
          </div>
          <div v-else class="swiperListSon">{{ item.title }}</div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="indexTitle">5000+元宇宙合作伙伴</div>
    <div class="indexText">服务于世界 500 强和众多国内外企业客户，包括中国电信，中国联通，BEYOND，等KA级客户，提供一站式元宇宙定制化解决方案</div>
    <i class="el-icon-arrow-down" style="margin-top:0px" /><br />
    <div class="iconBox">
      <swiper class="swiperBox swiper-wrapper" style="margin-bottom: 20px;" :options="swiperOption2()" ref="mySwiper">
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt1.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt2.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt3.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt4.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt5.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt6.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt7.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt8.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt9.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt10.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt11.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt12.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt13.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/tlbt14.png" alt="">
        </swiper-slide>
      </swiper>
      <swiper class="swiperBox swiperBox2 swiper-wrapper" style="width: 1070px;" :options="swiperOption3()" ref="mySwiper">
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt1.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt2.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt3.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt4.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt5.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt6.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt7.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt8.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt9.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt10.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt11.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt12.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt13.png" alt="">
        </swiper-slide>
        <swiper-slide class="swiperList swiperList2 swiper-slide">
          <img src="/img/blbt14.png" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="indexTitle">行业领军者的共同选择</div>
    <div class="indexText">广泛覆盖企业、高校、文旅等领域，提供元宇宙活动全流程多模态服务</div>
    <i class="el-icon-arrow-down" style="margin-top:0px" /><br />
    <div class="caseAllBox">
      <div class="caseBox" @click="openCase('https://mp.weixin.qq.com/s/Vm2twnMesBYA77HQALVApQ')">
        <img class="coverImg" src="/img/case1.png">
        <img class="logoImg" src="/img/case1_1.png">
        <div class="caseTitle">华东政法大学&兰迪律所元宇宙法庭</div>
        <div class="caseText">身临其境，高度沉浸；全球首场元宇宙案件模拟庭审现场</div>
        <div class="caseBtn">查看案例>></div>
      </div>
      <div class="caseBox" @click="openCase('https://mp.weixin.qq.com/s/fcREKQGdUsVeHAicwU-pvg')">
        <img class="coverImg" src="/img/case2.png">
        <img class="logoImg" src="/img/case2_2.png">
        <div class="caseTitle">中国电信人才发展中心元宇宙空间</div>
        <div class="caseText">10万平米，千人同屏；1:1孪生复刻多功能大楼</div>
        <div class="caseBtn">查看案例>></div>
      </div>
      <div class="caseBox" @click="openCase('https://mp.weixin.qq.com/s/1AdY0jaZETrXJZN22NEtLg')">
        <img class="coverImg" src="/img/case3.png">
        <img class="logoImg" src="/img/case3_3.png">
        <div class="caseTitle">东莞虎门粤港澳大湾区集邮展览</div>
        <div class="caseText">全国首个超大型元宇宙邮展；数字文旅，虚实共生</div>
        <div class="caseBtn">查看案例>></div>
      </div>
      <div class="caseBox" @click="openCase('https://mp.weixin.qq.com/s/zodC8asDnvtqsDTzrVhrdg')">
        <img class="coverImg" src="/img/case4.png">
        <img class="logoImg" src="/img/case4_4.png">
        <div class="caseTitle">世界创新峰会DEMO WORLD</div>
        <div class="caseText">覆盖全球，引领科技潮流；创业邦世界创新峰会</div>
        <div class="caseBtn">查看案例>></div>
      </div>
    </div>
    <div class="caseOpen" @click="to('ex')">查看更多客户案例>></div>
    <div>
      <div class="anchorBox" v-show="whiteStyle">
        <div v-for="(item, index) in dataList" :key="index" @click="jump(index,item.title)" :class="tabindex == index ?'anchorBoxBtn':''">
          {{ item.title.substring(item.title.length-3, 0)}}</div>
      </div>
      <div class="sectionBox">
        <div class="section" v-for="(item, index) in dataList" :key="index"  :style="stretch!=0?'height:'+((Math.ceil((item.subList.length)/4)*297)+107)+'px':''">
          <downtip :title="item.title" :tip="item.describe" />
          <div v-if="stretch===0" class="sampleBoxH5">
            <div class="sampleRoom" v-for="(itm, inx) in item.subList" :key="inx" @click="openUrl(itm.inviteLink,itm, item)">
              <div class="sampleImgBox">
                <img class="sampleImg" :src="itm.pic" alt="">
                <div class="sampleTopLeft">{{itm.sceneTypeStr=='office'?'办公':itm.sceneTypeStr=='meeting'?'会议':itm.sceneTypeStr=='space'?'园区':itm.sceneTypeStr=='bigHall'?'展会':'自由'}}空间</div>
                <div class="sampleTopRight" v-if="itm.isFree==1">免费</div>
              </div>
              <div class="sampleTextBox">
                <div class="sampleText">{{ itm.title }}</div>
                <div class="sampleTipBox">
                  <div class="sampleTip" v-for="(t, x) in itm.keyWordList" :key="x">{{ t }}</div>
                </div>
                <div class="hoverText">{{ itm.describe }}</div>
                <div class="sampleBtn" >进入体验</div>
              </div>
            </div>
          </div>
          <div v-else class="sampleBox">
            <div class="sampleRoom" v-for="(itm, inx) in item.subList" :key="inx" @click="openUrl(itm.inviteLink,itm, item)">
              <div class="sampleImgBox">
                <img class="sampleImg" :src="itm.pic" alt="">
                <div class="sampleTopLeft">{{itm.sceneTypeStr=='office'?'办公':itm.sceneTypeStr=='meeting'?'会议':itm.sceneTypeStr=='space'?'园区':itm.sceneTypeStr=='exhibitionHall'?'自由':'展会'}}空间</div>
                <div class="sampleTopRight" v-if="itm.isFree==1">免费</div>
              </div>
              <div class="sampleTextBox">
                <div class="sampleText">{{ itm.title }}</div>
                <div class="sampleTipBox">
                  <div class="sampleTip" v-for="(t, x) in itm.keyWordList" :key="x">{{ t }}</div>
                </div>
                <div class="hoverText">{{ itm.describe }}</div>
                <div class="sampleBtn">进入体验</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="out">
      <div class="w1040">
        <contactinfo />
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import contactinfo from '../components/contactinfo.vue'
import bottom from '../components/bottom.vue'
import downtip from '../components/downtip.vue'
import { mapState } from 'vuex'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'index',
  components: {
    navlist: nav,
    swiper,
    swiperSlide,
    contactinfo,
    bottom,
    downtip
  },
  data() {
    return {
      stretch: 0,
      tabindex: 0,
      scroll: '',
      swiperIndex: 0,
      visi: false,
      visipop: false,
      offi: 0,
      confi: 0,
      list: [],
      dataList: [],
      whiteStyle: false,
      maxScroll: 600
    }
  },
  created() {
    this.sceneSolutionListTab()
    this.os()
  },
  computed: {
    ...mapState(['isphone', 'widthmore', 'fulltrans'])
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('scroll', this.dataScroll)
    // window.addEventListener('click', () => {
    //   this.$refs.video.play()
    // })
    window.scrollTo(0, 0)
    window.addEventListener('scroll', (e) => {
      // console.log(window.pageYOffset + window.innerHeight)
      if (window.innerWidth < 600) {
        if (window.pageYOffset + window.innerHeight > 830 && this.list.indexOf('p1') === -1) {
          this.list.push('p1')
        }
        if (window.pageYOffset + window.innerHeight > 1370 && this.list.indexOf('p2') === -1) {
          this.list.push('p2')
        }
        // if (window.pageYOffset + window.innerHeight > 1830 && this.list.indexOf('p3') === -1) {
        //   this.list.push('p3')
        // }
        if (window.pageYOffset + window.innerHeight > 2060 && this.list.indexOf('p4') === -1) {
          this.list.push('p4')
        }
        if (window.pageYOffset + window.innerHeight > 3360 && this.list.indexOf('p5') === -1) {
          this.list.push('p5')
        }
        if (window.pageYOffset + window.innerHeight > 4800 && this.list.indexOf('p6') === -1) {
          this.list.push('p6')
        }
        if (window.pageYOffset + window.innerHeight > 4900 && this.list.indexOf('p7') === -1) {
          this.list.push('p7')
        }
        return
      }
      if (window.pageYOffset + window.innerHeight > 700 && this.list.indexOf('p1') === -1) {
        this.list.push('p1')
      }
      if (window.pageYOffset + window.innerHeight > 1400 && this.list.indexOf('p2') === -1) {
        this.list.push('p2')
      }
      // if (window.pageYOffset + window.innerHeight > 2400 && this.list.indexOf('p3') === -1) {
      //   this.list.push('p3')
      // }
      if (window.pageYOffset + window.innerHeight > 2100 && this.list.indexOf('p4') === -1) {
        this.list.push('p4')
      }
      if (window.pageYOffset + window.innerHeight > 2100 && this.list.indexOf('p5') === -1) {
        this.list.push('p5')
      }
      if (window.pageYOffset + window.innerHeight > 2400 && this.list.indexOf('p6') === -1) {
        this.list.push('p6')
      }
      if (window.pageYOffset + window.innerHeight > 2500 && this.list.indexOf('p7') === -1) {
        this.list.push('p7')
      }
    })
  },
  watch: {
    scroll() {
      this.loadSroll()
    }
  },
  methods: {
    openCase(item) {
      window.open(item)
    },
    handleScroll() {
      this.whiteStyle = window.document.documentElement.scrollTop >= this.maxScroll
    },
    openUrl(item, data, tabItem) {
      this.$track.reportEvent('solution_experience_click', {
        scene_type: this.$track.SCENE_2_TRACK_TYPE[data.sceneTypeStr].value,
        scene_id: data.sceneId,
        scene_name: data.title,
        solution_name: tabItem.title
      })
      window.open(item)
    },
    dataScroll() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
    },
    jump(index, item) {
      if (item) {
        this.$track.reportEvent('solution_card_click', { solution_name: item })
      }
      var jump = document.getElementsByClassName('section')
      // 获取需要滚动的距离
      var total = jump[index].offsetTop - 200
      // Chrome
      document.body.scrollTop = total
      // Firefox
      document.documentElement.scrollTop = total
      // Safari
      window.pageYOffset = total
      this.tabindex = index
    },
    loadSroll() {
      var self = this
      var sections = document.getElementsByClassName('section')
      for (var i = sections.length - 1; i >= 0; i--) {
        if (self.scroll >= sections[i].offsetTop - 220) {
          this.tabindex = i
          break
        }
      }
    },
    async sceneSolutionListTab() {
      const resp = await this.$http.post('sceneSolution/listTabWithSubList', {})
      if (resp.statusCode === 200) {
        this.dataList = resp.sceneSolutionDtoList
      } else { }
    },
    os () {
      var ua = navigator.userAgent
      var isWindowsPhone = /(?:Windows Phone)/.test(ua)
      var isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
      var isAndroid = /(?:Android)/.test(ua)
      var isFireFox = /(?:Firefox)/.test(ua)
      // var isChrome = /(?:Chrome|CriOS)/.test(ua)
      var isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
      var isPhone = /(?:iPhone)/.test(ua) && !isTablet
      var isPc = !isPhone && !isAndroid && !isSymbian
      if (isAndroid || isPhone) {
        this.stretch = 0
        return false
      } else if (isTablet) {
        return false
      } else if (isPc) {
        this.stretch = -50
        return true
      }
    },
    // this.stretch
    swiperOption() {
      return {
        effect: 'coverflow',
        loop: true,
        autoplay: true,
        initialSlide: 0,
        freeMode: false,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: { rotate: 10, stretch: this.stretch, depth: 200, modifier: 4, slideShadows: false },
        on: {
          slideChange: () => {
            // eslint-disable-next-line
            const c_swiper = document.getElementsByClassName('swiperBox')
            this.swiperIndex = c_swiper[0].swiper.activeIndex - this.dataList.length
            if (this.swiperIndex === this.dataList.length) {
              this.swiperIndex = 0
            }
            if (this.swiperIndex === -1) {
              this.swiperIndex = this.dataList.length - 1
            }
          }
        }
      }
    },
    swiperOption2() {
      return {
        loop: true,
        autoplay: {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        initialSlide: 0,
        slidesPerView: 'auto'
      }
    },
    swiperOption3() {
      return {
        loop: true,
        autoplay: {
          delay: 1500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          reverseDirection: true
        },
        initialSlide: 0,
        slidesPerView: 'auto'
      }
    },
    play() {
      this.visi = true
    },
    to(str) {
      window.scrollTo(0, 0)
      this.$router.push({
        path: './' + str
      })
    }
  }
}
</script>

<style scoped lang="scss">
.m /deep/ a {
  color: #fff
}

.m {
  margin-top: 20px;
}

.index {
  position: relative;
  width: 100%;
  background:#f6f6f6;

  .anchorBox {
    width: 100%;
    height: 72px;
    display: flex;
    position: fixed;
    z-index: 10;
    top: 67px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
    align-items: center;
    justify-content: space-evenly;

    div {
      opacity: 0.75;
      font-family: 苹方-简;
      font-size: 15px;
      font-weight: normal;
      line-height: 72px;
      text-align: center;
      letter-spacing: 0px;
      color: #22394E;
      cursor: pointer;
    }

    .anchorBoxBtn{
      opacity: 1;color: #22394E;font-size: 16px;font-weight: 600;border-bottom: 3px solid rgb(34, 57, 78);
    }
  }

  .sampleBox {
    width: 1265px;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    position: relative;
    min-height: 297px;
    // justify-content: space-between;

    .sampleRoom {
      margin: 0 8px;
      margin-bottom: 60px;
      cursor: pointer;

      .sampleImgBox{
        width: 300px;
        height: 170px;
        border-radius: 12.68px;
        overflow: hidden;
        position: relative;
        .sampleImg {
          width: 300px;
          height: 170px;
          border-radius: 12.68px;
          display: block;
          position: absolute;
        }

        .sampleTopLeft{
          width: 58px;
          height: 20px;
          border-radius: 3px;
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(10px);
          font-family: 苹方-简;
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0em;
          color: #FFFFFF;
          position: absolute;
          left: 5px;
          top:5px
        }

        .sampleTopRight{
          width: 62px;
          height: 27px;
          border-radius: 5px 12px 5px 12px;
          background: #FF4749;
          font-family: 苹方-简;
          font-size: 15px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
          color: #FFFFFF;
          position: absolute;
          right: 0;
        }
      }

      .sampleTextBox {
        width: 300px;
        margin-top: 12.68px;

        .sampleText {
          font-family: 苹方-简;
          font-size: 16px;
          font-weight: bolder;
          line-height: 22px;
          letter-spacing: 0.01em;
          color: #333333;
          margin-bottom: 9.51px;
          text-align: left;
        }

        .sampleTipBox {
          display: flex;
          flex-flow: wrap;

          .sampleTip {
            border-radius: 4px;
            background: #EAEAEA;
            font-family: 苹方-简;
            font-size: 12px;
            font-weight: normal;
            line-height: 12px;
            letter-spacing: 0em;
            color: #252B4F;
            padding: 5px 4px;
            margin-right: 6.34px;
          }
        }

        .hoverText,
        .sampleBtn {
          display: none;
        }
      }

      &:hover {
        transform: scale(1.1);
        margin-bottom: -800px;
        height: fit-content;
        box-shadow: 0px 3.46px 12.1px 0px rgba(0, 0, 0, 0.15);
        border-radius: 13.83px;
        overflow: hidden;
        position: relative;
        z-index: 4;
        .sampleImgBox{
          width: 300px;
          height: 170px;
          border-radius: 12.68px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          overflow: hidden;
          position: relative;
          .sampleImg {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .sampleTextBox {
          padding-top: 20px;
          margin-top: 0;
          background: #fff;
          padding-bottom: 20px;

          .sampleText {
            padding: 0 16px;
            margin-bottom: 16px;
          }

          .sampleTipBox {
            padding-left: 16px;
            margin-bottom: 20px;
          }

          .hoverText {
            font-family: 苹方-简;
            font-size: 14px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #22394E;
            display: block;
            text-align: left;
            margin: 0 16px;
            padding-top: 20px;
            border-top: 0.86px dashed #D8D8D8;
            margin-bottom: 21px;
          }

          .sampleBtn {
            width: 92.5px;
            height: 32.1px;
            border-radius: 5.19px;
            background: #252B4F;
            font-family: 苹方-简;
            font-size: 10.37px;
            font-weight: normal;
            line-height: 32.1px;
            text-align: center;
            letter-spacing: 0px;
            color: #D9F9E5;
            display: block;
            margin-left: 180px;
          }
        }

      }
    }
  }

  // .sampleRoom:last-child {
  //   &:hover{
  //     // margin-bottom: -140px;
  //   }
  // }

  .indexTitle {
    font-family: PingFang SC;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0px;
    color: #252B4F;
    margin-top: 100px;
  }

  .indexText {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    color: #252B4F;
    padding: 12px 32px;
  }

  .el-icon-arrow-down {
    font-size: 25px;
    font-weight: 800;
    color: #252b4f;
    margin-top: 23px;
  }

  .pc {
    display: block;
  }

  .phone {
    display: none;
  }

  .iconBox {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 36px;
    padding: 0 10px;

    img {
      width: 100%;
    }
  }

  .mask {

    // width: 100%;
    // height: 100%;
    &:nth-child(2) {
      z-index: 999;
    }

    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    video {
      width: 1200px;
      height: 733px;
      background: #000000;
      position: absolute;
      z-index: 10001;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    i {
      color: #fff;
      position: absolute;
      z-index: 2;
      left: calc(50% + 600px - 20px);
      top: calc(50% - 366px - 20px);
      font-size: 20px;
      cursor: pointer;

      &.blue {
        color: rgba(38, 48, 95, 1);
        left: calc(50% + 600px - 30px);
        top: calc(50% - 232px + 10px);
      }
    }
  }

  .video {
    width: 100%;
    height: 700px;
    background: #ccc;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient( rgba(0,0,0,0.4) 0,rgba(0,0,0,0.7) 100%);
    }

    .button {
      font-weight: bolder !important;
    }

    .bg {
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 700px !important;
      object-fit: cover;
    }

    .con {
      width: 1200px;
      position: absolute;
      left: 0;
      right: 0;
      top: 162px;
      margin: 0 auto;
      z-index: 3;
      display: flex;
      flex-direction: column;

      .t1 {
        font-family: 苹方-简;
        font-size: 35px;
        font-weight: 600;
        line-height: 35px;
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-shadow: 0px 0.63px 1.9px 0px rgba(0, 0, 0, 0.5);
      }

      .t2 {
        font-family: 苹方-简;
        font-size: 18px;
        font-weight: normal;
        line-height: 18px;
        text-align: left;
        letter-spacing: 0.13em;
        color: #FFFFFF;
        text-shadow: 0px 0.63px 1.9px 0px rgba(0, 0, 0, 0.5);
        margin-top: 11px;
        margin-bottom: 60px;
      }

      .topBtnBox {
        display: flex;

        .buttonBox {
          width: 197px;
          height: 64px;
          border-radius: 100px;
          background: #D9F9E5;
          border: 0px solid #D9F9E5;
          font-family: 苹方-简;
          font-size: 18px;
          font-weight: 500;
          line-height: 64px;
          text-align: center;
          letter-spacing: 0px;
          color: #252B4F;
          margin-right: 24px;
          cursor: pointer;
        }

        .play {
          width: 80px;
          height: 64px;
          border-radius: 100px;
          background: rgba(255, 255, 255, 0.3);
          border: 1.26px solid #FFFFFF;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 64px;
          cursor: pointer;

          i {
            font-size: 35px;
            color: #fff;
          }
        }
      }
    }
  }

  .rotationMap {
    max-width: 1685px;
    height: 356px;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -268px;
    z-index: 1;
    overflow: hidden;

    .swiperBox {
      width: 627px;
      height: 355px;
      overflow: initial;

      .swiperList {
        width: 627px;
        height: 355px;
        border-radius: 13px;
        background: radial-gradient(53% 53% at 50% 46%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.44) 100%);
        backdrop-filter: blur(2px);
        position: relative;
        overflow: hidden;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          z-index: -2;
        }

        .swiperListBox {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;

          .swiperListBoxTop {
            font-family: 苹方-简;
            font-size: 41.8px;
            font-weight: 500;
            line-height: 41.8px;
            text-align: center;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-shadow: 0 2px 2px rgba(0,0,0,0.7);
          }

          .swiperListBoxBottom {
            width: 500px;
            font-family: 苹方-简;
            font-size: 15.68px;
            font-weight: normal;
            line-height: 25.08px;
            text-align: center;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-shadow: 0 2px 2px rgba(0,0,0,0.5);
          }
        }

        .swiperListSon {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 15px;
          font-family: 苹方-简;
          font-size: 25.08px;
          font-weight: 500;
          line-height: 25.08px;
          text-align: center;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
      }
    }
  }

  .downtip {
    width: 1265px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 60px;
  }

  .bluepictext {
    margin-top: 100px;

    &:last-child {
      margin-bottom: 100px;
    }
  }

  .out {
    background: #D9F9E5;
    margin-top: 60px;
  }

  .w1040 {
    margin: 0 auto;
    width: 1040px;
    overflow: hidden;
    padding-top: 80px;

    .t {
      font-size: 28px;
      font-weight: 500;
      color: #26305F;
      line-height: 70px;
      -webkit-text-stroke: 1px #26305F;
      text-stroke: 1px #26305F;
      width: 100%;
      text-align: left;
    }

    .li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 80px;

      .infocard:not(:last-child) {
        margin-right: 32px;
      }
    }

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 50px;

      .on {
        opacity: 1;
        transform: translate(0, 0);
      }

      img {
        display: block;
        width: 136px;
        height: 136px;
        border: 1px solid #646464;
        border-radius: 136px;
        margin: 0 23px;
        overflow: hidden;
        transform: translate(100%, 0);
        opacity: 0;
        transition: all 0.3s ease;

        &:nth-child(2) {
          transition: all 0.3s ease 0.3s;
        }

        &:nth-child(3) {
          transition: all 0.2s ease 0.6s;
        }

        &:nth-child(4) {
          transition: all 0.2s ease 0.9s;
        }
      }
    }

    .comps {
      margin-top: 82px;
      margin-bottom: 58px;

      .tt {
        text-align: left;
        font-size: 28px;
        font-weight: 500;
        color: #252B4F;
        line-height: 70px;
        -webkit-text-stroke: 1px #26305F;
        text-stroke: 1px #26305F;
        margin-bottom: 24px;
      }

      .lis {
        display: flex;
        align-items: center;
        justify-content: center;

        .com {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 196px;
          width: 329px;

          &:not(:last-child) {
            border-right: 4px solid rgba(34, 44, 77, 1);
          }

          img {
            display: block;
            width: 132px;
            height: 76px;
          }

          .it {
            font-size: 40px;
            color: #222C4D;
            line-height: 40px;
            font-weight: bold;
            font-style: italic;

            span {
              font-size: 60px;
            }
          }

          .des {
            font-size: 24px;
            color: #222C4D;
            line-height: 40px;
          }
        }
      }
    }
  }

  .ppp {
    .infocard {
      /deep/ {
        overflow: hidden;

        >img {
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
}

.swiperList2{
  width: 164px;
  margin: 0 7px;
}

.caseAllBox{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  position: relative;
  height: 340px;
  margin-bottom: 36px;
  .caseBox{
    width: 285px;
    height: 316px;
    border-radius: 12px;
    background: #FFFFFF;

    .coverImg{
      width: 100%;
      height: 170px;
    }

    .logoImg{
      width: 253px;
      margin: 12px auto;
    }

    .caseTitle{
      padding:0 16px ;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      color: #333333;
      margin-bottom: 4px;
      text-align: left;
    }

    .caseText{
      padding:0 16px  ;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0em;
      color: rgba(51, 51, 51, 0.5);
      text-align: left;
    }

    .caseBtn{
      display: none;
    }

    &:hover{
      position: relative;
      height: 340px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

      .caseBtn{
        position: absolute;
        right: 16px;
        font-size: 12px;
        bottom: 16px;
        display: block;
        color: #252B4F;
      }
    }
  }
}

.caseOpen{
font-family: 苹方-简;
font-size: 16px;
font-weight: 500;
line-height: 16px;
text-align: center;
letter-spacing: 0px;
text-decoration: underline;
color: #6A79D3;
cursor: pointer;

}

@media screen and (max-width: 1265px) {

  .swiperBox2{
    width: 95% !important;
  }
  .swiperList2{
  width: 64px;
  margin: 0 7px;
}

.caseAllBox{
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  position: relative;
  margin-bottom: 36px;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-evenly;
  .caseBox{
    width: 45%;
    height: auto;
    border-radius: 12px;
    background: #FFFFFF;
    margin-bottom: 16px;

    .coverImg{
      height: 100px;
    }

    .logoImg{
      width: 85%;
      margin: 12px auto;
    }

    .caseTitle{
      padding:0 16px ;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0em;
      color: #333333;
      margin-bottom: 4px;
      text-align: left;
    }

    .caseText{
      padding:0 16px  ;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: rgba(51, 51, 51, 0.5);
      text-align: left;
      margin-bottom: 16px;
    }
  }
}

.caseOpen{
font-family: 苹方-简;
font-size: 16px;
font-weight: 500;
line-height: 16px;
text-align: center;
letter-spacing: 0px;
text-decoration: underline;
color: #6A79D3;
cursor: pointer;

}
  .m {
    margin-top: 0px;
  }

  .index {
    background: #f6f6f6;

    .downtip{
      width: 100%;
    }

    .anchorBox {
      width: 100%;
      height: 60px;
      display: flex;
      position: fixed;
      z-index: 10;
      top: 60px;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
      align-items: center;
      justify-content: space-evenly;

      div {
        opacity: 1;
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        color: #888A8B;
      }

      .anchorBoxBtn{
        opacity: 1;font-size: 18px;font-weight: 600;
        border-bottom: 2px solid #252B4F;
        color: #3D3D3D;
        line-height: 30px;
      }
  }

    .pc {
      display: none;
    }

    .phone {
      display: block;
    }

    .sampleBoxH5 {
      // pointer-events: none;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-flow: wrap;
      position: relative;
      min-height: 0px;
      // justify-content: space-evenly;
      // justify-content: space-between;

      .sampleRoom {
        width: 45vw;
        border-radius: 8px;
        background: #FFFFFF;
        margin: 0px 2.5vw;
        margin-bottom: 24px;
        cursor: pointer;
        padding: 5px 0;
        padding-bottom: 15px;

        .sampleImgBox{
          width: 98%;
          height: 115px;
          border-radius: 8px;
          position: relative;
          margin: 0 auto;
          .sampleImg {
            width: 100%;
            height: 115px;
            border-radius: 8px;
            display: block;
            position: absolute;
          }

          .sampleTopLeft{
            width: 58px;
            height: 20px;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(10px);
            font-family: 苹方-简;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
            color: #FFFFFF;
            position: absolute;
            left: 5px;
            top:5px
          }

          .sampleTopRight{
            width: 62px;
            height: 27px;
            border-radius: 5px 12px 5px 12px;
            background: #FF4749;
            font-family: 苹方-简;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            color: #FFFFFF;
            position: absolute;
            right: 0;
          }
        }

        .sampleTextBox {
          width: 40vw;
          margin-top: 12.68px;
          padding-left: 5px;

          .sampleText {
            font-family: 苹方-简;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.01em;
            color: #333333;
            margin-bottom: 9.51px;
            text-align: left;
          }

          .sampleTipBox {
            display: flex;
            flex-flow: wrap;

            .sampleTip {
              border-radius: 4px;
              background: #EAEAEA;
              font-family: 苹方-简;
              font-size: 12px;
              font-weight: normal;
              line-height: 12px;
              letter-spacing: 0em;
              color: #252B4F;
              padding: 5px 4px;
              margin-right: 6.34px;
              flex: none;
              margin-bottom: 9.7px;
            }
          }

          .hoverText,
          .sampleBtn {
            display: none;
          }
        }
      }

    }

    .rotationMap {
    width: 100%;
    height: 137.69px;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -110px;
    z-index: 1;
    overflow: hidden;

    .swiperBox {
      width: 242.98px;
      height: 137.69px;
      overflow: initial;

      .swiperList {
        width: 242.98px;
        height: 137.69px;
        border-radius: 4.86px;
        background: radial-gradient(53% 53% at 50% 46%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.44) 100%);
        backdrop-filter: blur(2px);
        position: relative;
        overflow: hidden;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          z-index: -2;
        }

        .swiperListBox {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;

          .swiperListBoxTop {
            font-family: 苹方-简;
            font-size: 16px;
            font-weight: 500;
            line-height: 41.8px;
            text-align: center;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
          }

          .swiperListBoxBottom {
            width: 98%;
            margin: 0 auto;
            font-family: 苹方-简;
            font-size: 12px;
            font-weight: normal;
            line-height:14px;
            text-align: center;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
          }
        }

        .swiperListSon {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 15px;
          font-family: 苹方-简;
          font-size: 16px;
          font-weight: 500;
          line-height: 25.08px;
          text-align: center;
          letter-spacing: 0px;
          color: #FFFFFF;
        }
      }
    }
  }

    .iconBox {
      padding: 0 20px;
      margin-top: 50px;

      img {
        width: 100%;
      }
    }

    .mask {
      i {
        right: 10px;
        left: unset;
        display: block;
        width: 20px;
        top: 10px;
        z-index: 10002;
        color: #fff;
      }

      video {
        width: 100vw;
        height: 100vh;
      }
    }

    .downtip {
      margin-top: 60px;
      margin-bottom: 32px;

      /deep/ .l1{
        font-size: 24px;
        color: #233057;
      }

      /deep/ .l2{
        opacity: 0.9;
        font-family: 苹方-简;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0px;
        color: rgba(35, 48, 87, 0.6);
        margin-top: 14px;
      }
    }

    .w1040 {
      width: 100%;

      .t {
        text-align: center;
      }

      .li {
        flex-direction: column;
        width: 100%;
      }
    }

    // display: none;
    .bluepictext {
      margin-top: 40px;
    }

    .video {
      width: 100%;
      height: 476px;
      // height: 669px;
      .bg {
        height: 669px;
        position: relative;
      }

      .con {
        width: 100%;
        align-items: center;

        // position: relative;
        // z-index: 1;
        // top: 0;
        // left: 0;
        // transform: none;
        .t1 {
          font-family: 苹方-简;
          font-size: 35.67px;
          font-weight: 600;
          line-height: 37.45px;
          text-align: center;
          letter-spacing: 0px;
          color: #FFFFFF;
          text-shadow: 0px 0px 0.89px 0px rgba(0, 0, 0, 0.5);
        }

        .t2 {
          font-family: 苹方-简;
          font-size: 13.38px;
          font-weight: 500;
          line-height: 13.38px;
          text-align: center;
          letter-spacing: 0.04em;
          color: #FFFFFF;
          text-shadow: 0px 0px 0.89px 0px rgba(0, 0, 0, 0.5);
        }

        .topBtnBox {
        display: flex;

        .buttonBox {
          width: 120.42px;
          height: 39.97px;
          border-radius: 61.31px;
          background: #D9F9E5;
          border: 0px solid #D9F9E5;
          font-family: 苹方-简;
          font-size: 14.27px;
          font-weight: 500;
          line-height:  39.97px;
          text-align: center;
          letter-spacing: 0px;
          color: #252B4F;
          margin-right: 24px;
          cursor: pointer;
        }

        .play {
          width: 42.8px;
          height: 39.24px;
          border-radius: 61.31px;
          background: rgba(255, 255, 255, 0.3);
          border: 1.26px solid #FFFFFF;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 64px;
          cursor: pointer;

          i {
            font-size: 24px;
            color: #fff;
          }
        }
      }

        .play {
          // display: none;
          width: 48px;
          height: 48px;
          border-width: 1px;

          i {
            font-size: 30px;
          }
        }

        // .button {
        //   display: none;
        // }
        .m {
          .button {
            display: flex;
          }
        }
      }
    }

    .w1040 {
      .comps {

        // display: none;
        .tt {
          text-align: center;
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #26305F;
          line-height: 23px;
          -webkit-text-stroke: 1px #26305F;
          text-stroke: 1px #26305F;
        }

        .lis {
          .com {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 88px;
            width: 165px;

            &:not(:last-child) {
              border-right: 2px solid rgba(34, 44, 77, 1);
            }

            img {
              display: block;
              width: 88px;
              height: 38px;
            }

            .it {
              font-size: 20px;
              color: #222C4D;
              line-height: 20px;
              font-weight: bold;
              font-style: italic;

              span {
                font-size: 30px;
              }
            }

            .des {
              font-size: 12px;
              color: #222C4D;
              line-height: 20px;
            }
          }
        }
      }
    }

    .w1040 {
      .logos {
        img {
          width: 80px;
          height: 80px;
          border: 1px solid #646464;
          border-radius: 100px;
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
