import { render, staticRenderFns } from "./bluepictext.vue?vue&type=template&id=0be2b678&scoped=true&"
import script from "./bluepictext.vue?vue&type=script&lang=js&"
export * from "./bluepictext.vue?vue&type=script&lang=js&"
import style0 from "./bluepictext.vue?vue&type=style&index=0&id=0be2b678&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be2b678",
  null
  
)

export default component.exports