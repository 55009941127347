import axios from 'axios'
var serverUrl = 'https://api.huadongmeta.com/'
const service = axios.create({
  baseURL: serverUrl,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    config.url += '?userId=0' + '&loginId=0'
    return config
  }
)

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 500:
          break
        case 401:
          break
        case 400:
          break
      }
      return {
        statusCode: error.response.status,
        msg: error.message
      }
    } else {
      if (axios.isCancel(error)) { // 取消请求的情况下，终端Promise调用链
        return {
          statusCode: '-1',
          msg: '请求取消'
        }
      }
      return {
        statusCode: -1,
        msg: '请求取消'
      }
    }
  }
)

export default service
