<template>
  <div class="office">
    <!-- @btn="to('index')" -->
      <!-- @btn="to('login')" -->
    <navlist
      @login="$emit('visi')"/>
    <bluehead
      title="ARK元宇宙办公"
      subtitle="和传统远程会议办公软件相比"
      :list="[
        '模拟线下团队办公场景，强调团队凝聚力',
        '远程监督考勤管理，解决远程管理问题',
        '碎片化会议沟通，音视频随时沟通'
      ]"
      :video="(!isphone || true) ? '/video/0首页-元宇宙办公.mp4' : ''"
      :src="(isphone && false) ? '/gif/0首页-元宇宙办公.GIF' : ''"
      btn="快来你的办公室看看"
      @btn="to()"
      down="随时，随地，随心，互通，互联"
      subdown="元宇宙营销展会，元宇宙沉浸式办公，元宇宙企业招聘，元宇宙校院宣讲会"/>
    <bluepictext
      :cls="{on: list.indexOf('p1') > -1}"
      :video="(!isphone || true) ? '/video/1产品页-办公-抬头见面随时找人.mp4' : ''"
      :src="(isphone && false) ? '/gif/1产品页-办公-抬头见面随时找人.GIF' : ''"
      verse="true"
      high="抬头见面 随时找人"
      content="想找谁，直接走到他身边，说话就行
所见即所得，不用再等他有空回复
减少沟通成本，提高管理效率"
      hide="true"
      btn="立即体验"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p2') > -1}"
      :src="['/img/拎包入住1.png','/img/拎包入住2.png', '/img/拎包入住3.png']"
      type="lineend"
      head="20个虚拟办公模版"
      high="拎包入驻"
      content="各种类型会议室，个性化的员工工位
公司品牌文化大厅，客户接待区，团建休闲区
高管办公室，办公室装修
这一切“拎包入驻” 即可获得"
      hide="true"
      btn="选择我的办公室"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p3') > -1}"
      :video="(!isphone || true) ? '/video/2产品页-办公-会议协作.mp4' : ''"
      :src="(isphone && false) ? '/gif/2产品页-办公-会议协作.GIF' : ''"
      verse="true"
      high="会议协作管理"
      type="highhead"
      title="瞬间直达"
      content="自动瞬移到会议地点
早午晚会不错过，保证信息同步，节奏一致"
      hide="true"
      btn="进去试试"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p4') > -1}"
      tt="true"
      :scale="isphone ? `${(313 / 176 / (2115 / 1080))}, 1` : `${(580 / 325 / (2115 / 1080))}, 1`"
      :video="(!isphone || true) ? '/video/3考勤管理.mp4' : ''"
      :src="(isphone && false) ? '/gif/3考勤管理.GIF' : ''"
      type="lineend"
      head="远程快照监督"
      high="考勤管理"
      content="随时了解员工工作状态，视频快照采集
人事部门进行考勤管理，便于进行数据分析管理
15分钟快照监督管理，保证员工们的出勤状态
有效提高工作的集中度"
      hide="true"
      btn="过来看看"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p5') > -1}"
      :video="(!isphone || true) ? '/video/4产品页-办公-私密空间.mp4' : ''"
      :src="(isphone && false) ? '/gif/4产品页-办公-私密空间.GIF' : ''"
      verse="true"
      high="私密沟通空间"
      type="highhead"
      title="安心畅聊"
      content="内置不同参会人数规格的会议室
兼顾沟通的顺畅自由和对话内容的私密性
谈薪、谈offer、谈公司机密，放心聊，没问题"
      hide="true"
      btn="进去聊聊"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p6') > -1}"
      :scale="isphone ? `${(313 / 176 / (1920 / 1066))}, 1` : `${(580 / 325 / (1920 / 1066))}, 1`"
      :video="(!isphone || true) ? '/video/5产品页-办公-随心搭配.mp4' : ''"
      :src="(isphone && false) ? '/gif/5产品页-办公-随心搭配.GIF' : ''"
      type="lineend"
      head="时尚虚拟形象"
      high="随心搭配"
      content="提供多款多配色人物预设形象
随时随地更换今日虚拟形象，保证新鲜感"
      hide="true"
      btn="换装看看"
      @btn="to()"/>
    <bluepictext
      :cls="{on: list.indexOf('p7') > -1}"
      :video="(!isphone || true) ? '/video/6产品页-办公-数字孪生.mp4' : ''"
      :src="(isphone && false) ? '/gif/6产品页-办公-数字孪生.GIF' : ''"
      verse="true"
      high="数字孪生办公室"
      type="highhead"
      title="身临其境"
      content="专业团队一比一孪生数字建模
让员工们在线上也能感受到办公室的温暖联结感
和亲密感与日俱增"
      hide="true"
      btn="加入体验"
      @btn="to()"/>
    <div class="out">
      <div class="w1040 infolist">
        <div class="tt">
          如何开始元宇宙办公之旅？
        </div>
        <div class="list">
          <infocard
            :cls="{on: list.indexOf('p8') > -1}"
            src="/img/如何开始1.jpg"
            :height="isphone ? 320 : 348"
            title="1. 定制团队专属办公室"
            content="专业团队一比一完美定制数字孪生办公室
员工置身其中，如同行在线下"/>
          <infocard
            :cls="{on: list.indexOf('p8') > -1}"
            src="/img/如何开始2.jpg"
            :height="isphone ? 320 : 348"
            title="2. ALL-IN-ONE办公工具系统"
            content="打卡考勤、文档共享、日程管理、待办事项……
ARK，一个搞定！"/>
          <infocard
            :cls="{on: list.indexOf('p8') > -1}"
            src="/img/如何开始3.jpg"
            :height="isphone ? 320 : 348"
            title="3. 享受沉浸式自在闲聊"
            content="和同事们聊天沟通节奏太慢？线上没有联结感？
Avatar们在一起，灵感随时随地迸发"/>
        </div>
        <contactinfo />
      </div>
    </div>
    <bottom />
  </div>
</template>

<script>
import nav from '../components/nav.vue'
import bluehead from '../components/bluehead.vue'
import bluepictext from '../components/bluepictext.vue'
import bottom from '../components/bottom.vue'
import contactinfo from '../components/contactinfo.vue'
import infocard from '../components/infocard.vue'
import { mapState } from 'vuex'

export default {
  name: 'office',
  components: {
    navlist: nav,
    bluehead,
    bluepictext,
    contactinfo,
    infocard,
    bottom
  },
  data () {
    return {
      list: [],
      officeUrl: null
    }
  },
  mounted () {
    var dataNum
    if (location.search === '') {
      dataNum = '?'
    } else {
      dataNum = location.search
    }
    this.officeUrl = 'https://www.huadongmeta.com/onlineark/index.html#/office' + dataNum
    window.scrollTo(0, 0)
    window.addEventListener('scroll', (e) => {
      // console.log(window.pageYOffset + window.innerHeight)
      if (window.innerWidth < 600) {
        if (window.pageYOffset + window.innerHeight > 830 && this.list.indexOf('p1') === -1) {
          this.list.push('p1')
        }
        if (window.pageYOffset + window.innerHeight > 1280 && this.list.indexOf('p2') === -1) {
          this.list.push('p2')
        }
        if (window.pageYOffset + window.innerHeight > 1700 && this.list.indexOf('p3') === -1) {
          this.list.push('p3')
        }
        if (window.pageYOffset + window.innerHeight > 2070 && this.list.indexOf('p4') === -1) {
          this.list.push('p4')
        }
        if (window.pageYOffset + window.innerHeight > 2500 && this.list.indexOf('p5') === -1) {
          this.list.push('p5')
        }
        if (window.pageYOffset + window.innerHeight > 2900 && this.list.indexOf('p6') === -1) {
          this.list.push('p6')
        }
        if (window.pageYOffset + window.innerHeight > 3300 && this.list.indexOf('p7') === -1) {
          this.list.push('p7')
        }
        if (window.pageYOffset + window.innerHeight > 3800 && this.list.indexOf('p8') === -1) {
          this.list.push('p8')
        }
      }
      if (window.pageYOffset + window.innerHeight > 1470 && this.list.indexOf('p1') === -1) {
        this.list.push('p1')
      }
      if (window.pageYOffset + window.innerHeight > 1960 && this.list.indexOf('p2') === -1) {
        this.list.push('p2')
      }
      if (window.pageYOffset + window.innerHeight > 2400 && this.list.indexOf('p3') === -1) {
        this.list.push('p3')
      }
      if (window.pageYOffset + window.innerHeight > 2880 && this.list.indexOf('p4') === -1) {
        this.list.push('p4')
      }
      if (window.pageYOffset + window.innerHeight > 3360 && this.list.indexOf('p5') === -1) {
        this.list.push('p5')
      }
      if (window.pageYOffset + window.innerHeight > 3830 && this.list.indexOf('p6') === -1) {
        this.list.push('p6')
      }
      if (window.pageYOffset + window.innerHeight > 4290 && this.list.indexOf('p7') === -1) {
        this.list.push('p7')
      }
      if (window.pageYOffset + window.innerHeight > 4920 && this.list.indexOf('p8') === -1) {
        this.list.push('p8')
      }
    })
  },
  computed: {
    ...mapState(['isphone'])
  },
  methods: {
    to () {
      // window.scrollTo(0, 0)
      // this.$router.push({
      //   path: './' + str
      // })
      window.open(this.officeUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.office {
  background: #F6F6F6;
  > .bluepictext {
    margin-bottom: 100px;
    &:nth-child(3) {
      margin-top: 80px;
    }
    &:not(.verse) /deep/ .item {
      &:first-child {
        margin-right: 100px;
      }
    }
  }
  .out {
    background: #D9F9E5;
  }
  .w1040 {
    margin: 0 auto;
    width: 1040px;
    overflow: hidden;
    padding-top: 80px;
  }
  .infolist {
    .tt {
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #26305F;
      line-height: 70px;
      -webkit-text-stroke: 1px #26305F;
      text-stroke: 1px #26305F;
      text-align: left;
    }
    .list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 80px;
      .infocard:not(:last-child) {
        margin-right: 32px;
      }
      .infocard {
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .office {
    .bluepictext {
      margin: 40px 0 !important;
      /deep/ {
        .bluetextinfo {
          height: auto !important;
        }
      }
    }
    .w1040 {
      width: 100%;
      .tt {
        text-align: center;
      }
      .list {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>
